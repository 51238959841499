import React, {Component} from 'react';
import SubTitle from '../Components/SubTitle';
import SelectionPanel from '../Components/SelectionPanel';
import _ from 'lodash';

export default class CardChooser extends Component{
    constructor(props){
        super(props);
        this.state = {
            selectedCard: -1,
            cardsList: props.cardsList
        };
        this.selectCard = this.selectCard.bind(this);
        this.selectRandom = this.selectRandom.bind(this);
        this.confirm = this.confirm.bind(this);
        this.search = this.search.bind(this);
        this.sendCard = this.sendCard.bind(this);
        this.setSelectedCard = this.setSelectedCard.bind(this);
    }

    componentDidMount(){
        this.setState({
            selectedCard: -1
        }, this.nameInput.focus());
    }

    setSelectedCard(cardIndex, oldCard, cardsList){
        const state = {
            selectedCard: cardIndex
        };
        if(cardsList){
            state.cardsList = cardsList;
        }
        this.setState(state, () => {
            if(cardIndex !== oldCard){
                this.ttl.focus();
            }
        });
    }

    search(event){
        const newCardList = _.filter(this.props.cardsList, (card) => {
            return card.name.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1;
        });
        let selectedCard = this.state.selectedCard;
        if(newCardList.length === 1){
            selectedCard = this.props.cardsList.indexOf(newCardList[0]);
        }
        this.setSelectedCard(selectedCard, selectedCard, newCardList);
    }

    sendCard(idx){
        this.props.chooseCard(this.props.selectedCardsIndex,
            this.props.cardsList,
            idx,
            this.props.closingMethod);
    }

    selectCard(name){
        const idx = _.findIndex(this.props.cardsList, (card) => card.name === name);
        this.setSelectedCard(idx);
    }

    confirm(event){
        if(event.charCode === 13 && this.state.cardsList.length > 0){
            const idx = this.props.cardsList.indexOf(this.state.cardsList[0]);
            this.setSelectedCard(idx);
        }
    }

    selectRandom(){
        const max = this.props.cardsList.length;
        const rnd = Math.floor(Math.random() * max);
        this.setSelectedCard(rnd, null, this.props.cardsList);
    }

    render(){
        return(
            <SelectionPanel onClick={this.props.closingMethod}>
                <div role="document" id="dialog" aria-label={this.props.title} className="cardChooser">
                    <SubTitle text={this.props.title}/>
                    <div className="chooserContainer" role="region" aria-label="Recherche et Sélection">
                    <input  type="text"
                            placeholder="Rechercher..."
                            style={{marginBottom:0}}
                            onChange={this.search}
                            onKeyPress={this.confirm}
                            ref={(input) => {this.nameInput = input;}}
                            list="cards"/>
                    <datalist id="cards">
                    {
                        this.state.cardsList.map((card, index) => {
                            return(
                                <option value={card.name} key={index} />
                            );
                        })
                    }    
                    </datalist>
                    <button onClick={this.selectRandom}>Choisir au hasard</button>
                    {
                        this.state.cardsList.map((card, index) => {
                            return(
                                <button className="bigCard" index={index} key={card.name} 
                                onClick={() => this.selectCard(card.name)}
                                aria-label={card.name}>
                                    <h4>{card.name}</h4>
                                    <img src={'./img/' + card.image} alt="" />
                                </button>);
                        })
                    }
                    </div>
                    <div className="descriptionContainer" role="region" aria-label="Description">
                        {
                            this.state.selectedCard > -1 ?
                            <div>
                                <div className="hugeCardContainer">
                                    <div className="hugeCard">
                                        <h3 aria-hidden="true">{this.props.cardsList[this.state.selectedCard].name}</h3>
                                        <img src={'./img/' + this.props.cardsList[this.state.selectedCard].image} alt="" />
                                    </div>
                                </div>
                                <h3 className="bigCardTitle">{this.props.cardsList[this.state.selectedCard].name}</h3>
                                <div className="cardDescription" dangerouslySetInnerHTML={{__html: this.props.cardsList[this.state.selectedCard].description}}></div>
                                <div className="freins">{this.props.cardsList[this.state.selectedCard].freins}</div>
                            </div> : ''
                        }
                    </div>
                    <div className="buttonChoiceContainer">
                        <button  ref={(ttl) => {this.ttl = ttl;}} 
                                disabled={this.state.selectedCard > -1 ? false : true} 
                                aria-label={'Ajouter ' + this.state.selectedCard > -1 ? this.props.cardsList[this.state.selectedCard].name : ''}
                                onClick={() => {this.props.chooseCard(this.props.selectedCardsIndex,
                                                                    this.props.cardsList,
                                                                    this.state.selectedCard,
                                                                    this.props.closingMethod)}}>
                            {'Ajouter ' + (this.state.selectedCard > -1 ? this.props.cardsList[this.state.selectedCard].name : '')}
                        </button>
                    </div>
                </div>
            </SelectionPanel>
        );
    }
}