class User {
    id;
    username;
    fullname;
    admin;
    key;
    password;
    
    constructor(id, username, fullname, admin, key, password){
        this.id = id;
        this.username = username;
        this.fullname = fullname;
        this.admin = admin;
        this.key = key;
        this.password = password;
    }
}

export default User;