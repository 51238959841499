import React, { Component } from 'react';
import { Prompt } from "react-router-dom";
import _ from 'lodash';
import ContentTitle from '../Components/ContentTitle';
import Enums from '../Model/Enums';
import Scras from '../Resources/Scras';
import Zamis from '../Resources/Zamis';
import Attitudes from '../Resources/Attitudes';
import Citations from '../Resources/Citations';
import Traverse from '../Resources/Traverse';
import CardChooser from './CardChooser';
import CitationChooser from './CitationChooser';
import CardSelector from '../Components/CardSelector';
import CitationSelector from '../Components/CitationSelector';
import InputChooser from './InputChooser';
import CardShower from '../Components/CardShower';
import CitationShower from '../Components/CitationShower';
import InputShower from '../Components/InputShower';
import SaveGamePrompt from './SaveGamePrompt';
import {saveGame} from '../Communication/Api';

var pastStates = [];
const citationLabels = [
    'Première citation',
    'Seconde citation',
    'Troisième citation'
];
const obstacleLabels = [
    'Premier obstacle',
    'Second obstacle',
    'Dernier obstacle'
];

function bindFunctions(self){
    self.handleChange = self.handleChange.bind(self);
    self.focusArea = self.focusArea.bind(self);
    self.nextStep = self.nextStep.bind(self);
    self.toggleSelectionPanel = self.toggleSelectionPanel.bind(self);
    self.chooseCard = self.chooseCard.bind(self);
    self.chooseAttitude = self.chooseAttitude.bind(self);
    self.chooseCitation = self.chooseCitation.bind(self);
    self.advanceSetup = self.advanceSetup.bind(self);
    self.addCard = self.addCard.bind(self);
    self.saveState = self.saveState.bind(self);
    self.prevStep = self.prevStep.bind(self);
    self.showCard = self.showCard.bind(self);
    self.hideCard = self.hideCard.bind(self);
    self.showCitation = self.showCitation.bind(self);
    self.hideCitation = self.hideCitation.bind(self);
    self.showInput = self.showInput.bind(self);
    self.hideInput = self.hideInput.bind(self);
    self.getSelectionPanel = self.getSelectionPanel.bind(self);
    self.saveGameInternal = self.saveGameInternal.bind(self);
    self.updateZamisList = self.updateZamisList.bind(self);
    self.getConfig = self.getConfig.bind(self);
    self.getObstacles = self.getObstacles.bind(self);
    self.addObstacle = self.addObstacle.bind(self);
    self.setObstacle = self.setObstacle.bind(self);
    self.muteSound = self.muteSound.bind(self);
    self.handleNextStep = self.handleNextStep.bind(self);
    self.focusLastTarget = self.focusLastTarget.bind(self);
    self.displaySaveGameDialog = self.displaySaveGameDialog.bind(self);
    self.hideSaveGameDialog = self.hideSaveGameDialog.bind(self);
    self.deleteCard = self.deleteCard.bind(self);
    self.addStory = self.addStory.bind(self);
    self.setStory = self.setStory.bind(self);
    self.deleteCitation = self.deleteCitation.bind(self);
}

export default class Game extends Component{
    constructor(props){
        super(props);
        bindFunctions(this);
        this.updateZamisList();
        this.Enums = Enums;
        const startingState = Enums.gameStatus.definition;
        if(props.loadedGame){
            const tempState = props.loadedGame;
            // Reset music state
            tempState.playingMusic = false;
            // Transfer old data to new format
            if(!tempState.story){
                tempState.story = [
                    tempState.startState,
                    tempState.endState,
                    tempState.secondaryState[0]
                ];
            }
            if(!tempState.selectedAttitude[0]){
                const tempAttitude = [tempState.selectedAttitude];
                tempState.selectedAttitude = [tempAttitude];
            }
            tempState.avatarPosition = this.computeAvatarPosition(tempState.gameState);

            this.state = tempState;
        } else {
            this.state = {
                gameState: startingState,
                story: ['', '', ''],
                saved: false,
                avatarPosition: 'avatarStart',
                obstacles: ['', '', ''],
                selectedScras: [[], [], []],
                selectedZamis: [[], [], []],
                selectedCitations: [null, null, null],
                selectedDetours: [[], [], [], [], [], []],
                selectedAttitude: [[]],
                selecting: '',
                addingTo: -1,
                cardToShow: null,
                citationToShow: null,
                inputToShow: null,
                savingGame: false,
                playingMusic: false,
                musicSrc: null,
                isGameHidden: true
            };
        }
        this.areas = [];
        for(var i = 0; i < 6; i++){
            this.areas.push(React.createRef());
        }
    }

    updateZamisList(){
        this.zamisList = Object.assign([], Zamis);
        if(!this.getConfig().useMagic){
            this.zamisList.splice(4,1);
        }
    }

    getConfig(){
        if(this.props.config)
            return this.props.config;
        return {
            useMagic: false,
            useDetours: false,
            useAttitudes: false
        }
    }

    componentDidUpdate(prevProps){
        this.updateZamisList();
        this.render();
    }

    toggleFullScreen(){
        if (document.fullscreenElement || 
            document.webkitFullscreenElement || 
            document.mozFullScreenElement) {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) { /* Firefox */
            document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
            document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { /* IE/Edge */
            document.msExitFullscreen();
            }
        } else {
            document.getElementsByClassName("app")[0].requestFullscreen();
        }
    }

    componentDidMount(){
        document.getElementsByClassName("app")[0].requestFullscreen();
        this.advanceSetup();
    }

    componentWillUnmount(){
        if (document.fullscreenElement || 
            document.webkitFullscreenElement || 
            document.mozFullScreenElement) {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) { /* Firefox */
            document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
            document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { /* IE/Edge */
            document.msExitFullscreen();
            }
        }
    }

    saveState(state, callback){
        const cleanPastState = _.cloneDeep(this.state);
        cleanPastState.selecting = '';
        pastStates.push(cleanPastState);
        const newState = Object.assign({}, this.state, state);
        newState.saved = false;
        this.setState(newState, callback);
    }

    saveGameInternal(name, place, description, participants){
        const pastState = _.cloneDeep(this.state);
        pastState.savingGame = false;
        pastState.isGameHidden = false;
        const savedGame = {
            config: Object.assign({}, this.props.config),
            game: pastState
        }
        saveGame(savedGame, name, place, description, participants, this.props.user.id, this.props.user.username, this.props.user.key);
        this.setState({savingGame : false, saved: true});
    }

    advanceSetup(){
        let state = Object.assign({}, this.state);
        switch(state.gameState){
            case this.Enums.gameStatus.definition:
                this.setState({
                    gameState: 1
                }, () => {this.addStory(0)});
                break;
            case this.Enums.gameStatus.startDefined:
                this.setState({
                    gameState: 2
                }, () => {this.addStory(1)});
                break;
            case this.Enums.gameStatus.endDefined:
                this.setState({
                    gameState: 3
                }, () => {this.addStory(2)});
                break;
            case this.Enums.gameStatus.secondaryDefined:
                this.setState({
                    gameState: 4
                }, () => {this.addObstacle(0)});
                break;
            case this.Enums.gameStatus.firstObstacleDefined:
                this.setState({
                    gameState: 5
                }, () => {this.addObstacle(1)});
                break;
            case this.Enums.gameStatus.secondObstacleDefined:
                this.setState({
                    gameState: 6
                }, () => {this.addObstacle(2)});
                break;
            case this.Enums.gameStatus.thirdObstacleDefined:
                const newState = this.getConfig().useAttitudes ? 7 : 8;
                const fct = this.getConfig().useAttitudes ? () => {this.addCard(0, () => this.toggleSelectionPanel('Attitude'))} : () => {return true;};
                this.setState({
                    gameState: newState
                }, fct);
                break;
            default: break;
        }
    }

    handleChange(event){
        this.saveState({
            [event.target.id]: event.target.value
        });
    }

    focusArea(id){
        this.areas[id].focus();
    }

    focusLastTarget(){
        if(this.lastTarget){
            this.lastTarget.focus();
            this.lastTarget = null;
        }
    }

    computeAvatarPosition(gameState){
        switch(gameState){
            case this.Enums.gameStatus.firstStep: return 'avatarFirstStep';
            case this.Enums.gameStatus.secondStep: return 'avatarSecondStep';
            case this.Enums.gameStatus.thirdStep: return 'avatarThirdStep';
            case this.Enums.gameStatus.finished: return 'avatarFinished';
            default: return 'avatarStart';
        }
    }

    handleNextStep(){
        const newState = this.state.gameState + 1;
        const playMusic = newState === this.Enums.gameStatus.firstStep ||
                            newState === this.Enums.gameStatus.secondStep ||
                            newState === this.Enums.gameStatus.thirdStep ||
                            newState === this.Enums.gameStatus.finished;
        let file = '';
        switch(newState){
            case this.Enums.gameStatus.finished: file = 'music/finished.mp3'; break;
            case this.Enums.gameStatus.firstStep: file = 'music/Obstacle 1.mp3'; break;
            case this.Enums.gameStatus.secondStep: file = 'music/Obstacle 2.mp3'; break;
            case this.Enums.gameStatus.thirdStep: file = 'music/Obstacle 3.mp3'; break;
            default: break;
        }
        this.saveState({
            gameState: newState,
            avatarPosition: this.computeAvatarPosition(newState),
            playingMusic: playMusic,
            musicSrc: file
        });
    }

    nextStep(){
        if(this.state.gameState < this.Enums.gameStatus.finished){
            if(this.state.playingMusic){
                this.setState({
                    playingMusic: false
                }, this.handleNextStep);
            } else {
                this.handleNextStep();
            }
        }
    }

    toggleSelectionPanel(panel){
        const newState = this.state.selecting && this.state.selecting !== '' ? '' : panel;
        this.setState({
            selecting: newState,
            isGameHidden: newState ? false : true
        });
    }

    addCard(num, callback){
        this.setState({
            addingTo: num
        }, callback);
    }

    chooseAttitude(cardsRef, listCards, idx, callback){
        let state = Object.assign({}, this.state);
        state.gameState = this.Enums.gameStatus.started;
        state.selectedAttitude = [[idx]];
        state.isGameHidden = false;
        this.saveState(state, callback);
    }

    chooseCard(cardsRef, listCards, idx, callback){
        let selectedCards = Object.assign([], this.state[cardsRef]);
        if(selectedCards.length > this.state.addingTo && this.state.addingTo > -1){
            let cards = selectedCards[this.state.addingTo];
            cards.push(idx);
            this.saveState({[cardsRef]: selectedCards}, callback);
        } else {
            callback();
        }
    }

    showCard(card, target, idx, type){
        this.lastTarget = target;
        this.setState({
            cardToShow: {
                card,
                idx,
                type
            },
            isGameHidden: true
        });
    }

    hideCard(){
        this.setState({
            cardToShow: null,
            isGameHidden: false
        }, this.focusLastTarget);
    }

    showCitation(card, target, idx){
        this.lastTarget = target;
        this.setState({
            citationToShow: Citations[card],
            isGameHidden: true,
            addingTo: idx
        });
    }

    deleteCitation(){
        let citations = Object.assign({}, this.state.selectedCitations);
        citations[this.state.addingTo] = null;
        this.saveState({
            selectedCitations: citations,
            addingTo: -1,
        }, this.hideCitation);
    }

    hideCitation(){
        this.setState({
            citationToShow: null,
            isGameHidden: false
        }, this.focusLastTarget);
    }

    showInput(text, target){
        this.lastTarget = target;
        this.setState({
            inputToShow: text,
            isGameHidden: true
        });
    }

    hideInput(){
        this.setState({
            inputToShow: null,
            isGameHidden: false
        }, this.focusLastTarget);
    }

    chooseCitation(citationsRef, idx, callback){
        let state = Object.assign({}, this.state);
        state[citationsRef][this.state.addingTo] = idx;
        this.saveState(state, callback);
    }

    prevStep(){
        if(pastStates.length > 0){
            const prevState = pastStates.pop();
            this.setState(prevState);
        }
    }

    getDetour(idx, label){
        let detours = [];
        const title = 'Chemin de travers';
        const labels = [
            {
                index: (idx*2) + 1,
                title: title + ' 1 sur 2 ' + label
            },
            {
                index: (idx*2) + 2,
                title: title + ' 2 sur 2 ' + label
            }
        ];
        for(var i = 0; i < labels.length; i++){
            const labIndex = labels[i].index;
            detours.push(
                <div key={labIndex} id={`detour${labIndex}`} className="absolute smallCardSelector">
                    <CardSelector selectedCards={this.state.selectedDetours[labIndex - 1]}
                                cardsList={Traverse}
                                addCard={() => {this.addCard(labIndex - 1, () => this.toggleSelectionPanel('Chemins de travers'))}}
                                limit={1} 
                                title={title}
                                label={labels[i].title}
                                notFocusable={this.state.isGameHidden}
                                small={true}/>
                </div>);
        }
        return detours;
    }

    setObstacle(text){
        let obstacles = Object.assign([], this.state.obstacles);
        obstacles[this.state.addingTo] = text;
        const fct = this.state.gameState < Enums.gameStatus.started ? this.advanceSetup : this.focusLastTarget;
        this.saveState({
            addingTo: null,
            selecting: '',
            obstacles: obstacles,
            isGameHidden: false
        }, fct);
    }

    setStory(text){
        let story = Object.assign([], this.state.story);
        story[this.state.addingTo] = text;
        const fct = this.state.gameState < Enums.gameStatus.started ? this.advanceSetup : this.focusLastTarget;
        this.saveState({
            addingTo: null,
            selecting: '',
            story: story,
            isGameHidden: false
        }, fct);
    }

    addStory(id, target){
        this.lastTarget = target;
        this.setState({
            addingTo: id,
            selecting: 'Story',
            isGameHidden: true
        });
    }

    addObstacle(id, target){
        this.lastTarget = target;
        this.setState({
            addingTo: id,
            selecting: 'Obstacles',
            isGameHidden: true
        });
    }

    hideSaveGameDialog(){
        this.setState({
            savingGame: false, 
            isGameHidden: false
        }, this.focusLastTarget);
    }

    displaySaveGameDialog(target){
        this.lastTarget = target;
        this.setState({
            savingGame: true, 
            isGameHidden: true
        });
    }

    capitalizeFirstLetter(word){
        return word.charAt(0).toUpperCase() + word.slice(1);
    }

    deleteCard(){
        if(this.state.cardToShow == null){
            return;
        }
        const idxName = 'selected' + this.capitalizeFirstLetter(this.state.cardToShow.type);
        const collection = Object.assign({}, this.state[idxName]);
        let cardList = [];
        switch(this.state.cardToShow.type){
            case 'scras': cardList = Scras; break;
            case 'zamis': cardList = this.zamisList; break;
            case 'attitude': cardList = Attitudes; break;
            default: break;
        }
        const idCard = cardList.indexOf(this.state.cardToShow.card);
        collection[this.state.cardToShow.idx] = _.filter(collection[this.state.cardToShow.idx], (cardsIds) => {
            return cardsIds !== idCard;
        });
        this.saveState({
            [idxName]: collection,
            cardToShow: null
        });
    }

    getScraZami(type, stateRef, cardsList, label, idx){
        const title = this.capitalizeFirstLetter(type);
        let nbCards = 1;
        if(type === 'scras'){
            nbCards = 3;
        } else if(type === 'zamis'){
            nbCards = 2;
        }
        return (
        <div id={type+(idx+1)} className="absolute cardSelector">
            <CardSelector showCard={(card, target) => {this.showCard(card, target, idx, type)}}
                        selectedCards={stateRef[idx]}
                        cardsList={cardsList}
                        addCard={() => {this.addCard(idx, () => this.toggleSelectionPanel(title))}} 
                        limit={nbCards}
                        label={title + ' ' + label}
                        notFocusable={this.state.isGameHidden}
                        title={title} />
        </div>);
    }

    getCitation(idx){
        return (
        <div key={`cit${idx+1}`} id={`cit${idx+1}`} className="absolute smallCardSelector">
            <CitationSelector selectedCitation={this.state.selectedCitations[idx]}
                addCard={() => {this.addCard(idx, () => this.toggleSelectionPanel('Citations'))}}
                limit={1}
                label={citationLabels[idx]}
                title='Citations'
                small={true}
                showCitation={(citation, target) => {this.showCitation(citation, target, idx)}}
                notFocusable={this.state.isGameHidden}
                zamiSage={_.find(Zamis, (zami) => { return zami.name ==='ZamiSage' })}/>
        </div>);
    }

    getObstacle(label, idx){
        return (
            <div key={label+idx} role="region" aria-label={label}>
                <button tabIndex={this.state.isGameHidden ? -1 : 1} id={'obstacle' + idx}
                    className="absolute obstaclePanel" 
                    onClick={(evt) => this.addObstacle(idx, evt.target)}
                    aria-label={label + (this.state.obstacles[idx] ? (': ' + this.state.obstacles[idx]) : '')}>
                    {this.state.obstacles[idx] ? this.state.obstacles[idx] : ''}
                </button>
                {
                    this.getScraZami('scras', this.state.selectedScras, Scras, label, idx)
                }
                {
                    this.getScraZami('zamis', this.state.selectedZamis, this.zamisList, label, idx)
                }
                {
                    citationLabels[idx] ? this.getCitation(idx) : ''
                }
                {
                    this.getConfig().useDetours ? 
                        <React.Fragment>
                            {this.getDetour(idx, label)}
                        </React.Fragment> : ''
                }
            </div>
        );
    }

    getObstacles(){
        let obstacles = [];
        for(let i = 0; i < obstacleLabels.length; i++){
            obstacles.push(this.getObstacle(obstacleLabels[i], i));
        }
        return obstacles;
    }

    muteSound(){
        this.setState({
            playingMusic: false
        });
    }

    getSelectionPanel(){
        if(this.state.selecting === '')
            return '';
        const storyTitles = [
            'Quelle est la situation de départ ?',
            'Quel est l\'état désiré ?',
            'Quels sont les bénéfices secondaires ?'
        ];
        const obstacleTitles = [
            'Quel est le premier obstacle rencontré ?',
            'Quel est le second obstacle rencontré ?',
            'Quel est le dernier obstacle rencontré ?'
        ]
        let element = null;
        switch(this.state.selecting){
            case 'Scras':
                element = <CardChooser    cardsList={Scras} 
                            selectedCardsIndex={'selectedScras'}
                            chooseCard={this.chooseCard} 
                            closingMethod={this.toggleSelectionPanel}
                            title={'Ajouter un Scra'}/>;
                break;
            case 'Zamis':
                element = <CardChooser    cardsList={this.zamisList} 
                            selectedCardsIndex={'selectedZamis'}
                            chooseCard={this.chooseCard} 
                            closingMethod={this.toggleSelectionPanel}
                            title={'Ajouter un Zami'}/>;
                break;
            case 'Chemins de travers':
                element = <CardChooser    cardsList={Traverse} 
                            selectedCardsIndex={'selectedDetours'}
                            chooseCard={this.chooseCard} 
                            closingMethod={this.toggleSelectionPanel}
                            title={'Choisir un chemin de traverse'}/>;
                break;
            case 'Citations':
                element = <CitationChooser cardsList={Citations}
                            selectedCardsIndex={'selectedCitations'}
                            chooseCitation={this.chooseCitation}
                            closingMethod={this.toggleSelectionPanel}
                            title={'Choisir une citation'}/>;
                break;
            case 'Obstacles':
                element = <InputChooser returnText={this.setObstacle}
                            title={obstacleTitles[this.state.addingTo]}
                            actualText={this.state.obstacles[this.state.addingTo]}
                            closingMethod={this.toggleSelectionPanel}
                            maxLength={150}/>;
                break;
            case 'Story':
                element = <InputChooser returnText={this.setStory}
                            title={storyTitles[this.state.addingTo]}
                            actualText={this.state.story[this.state.addingTo]}
                            maxLength={150}
                            closingMethod={this.toggleSelectionPanel}
                            emptyOk={true}/>;
                break;
            case 'Attitude':
                element = <CardChooser    cardsList={Attitudes} 
                            selectedCardsIndex={'selectedAttitude'}
                            chooseCard={this.chooseAttitude} 
                            closingMethod={this.toggleSelectionPanel}
                            title={'Choisir une attitude'}/>;
                break;
            default: element = '';
        }
        return element;
    }

    render() {
        return (
            <div className="content reduced" id="gameContent">
                <Prompt when={!this.state.saved} message="Les modifications non sauvegardées seront perdues. Êtes-vous sûr de vouloir quitter la partie ?" />
                <ContentTitle text="Chemin de Vie"/>
                <div className="">
                <button id="fullScreenToggle" aria-hidden="true" onClick={this.toggleFullScreen} tabIndex="-1"><i className="material-icons" tabIndex="-1">zoom_out_map</i></button>
                {
                    this.getSelectionPanel()
                }
                {
                    this.state.cardToShow ?
                        <CardShower card={this.state.cardToShow.card}
                                    closingMethod={this.hideCard}
                                    deleteCard={this.deleteCard}/> : ''
                }
                {
                    this.state.citationToShow ?
                        <CitationShower citation={this.state.citationToShow}
                                    closingMethod={this.hideCitation}
                                    deleteCard={this.deleteCitation}/> : ''
                }
                {
                    this.state.inputToShow ?
                        <InputShower title={this.state.inputToShow.title} 
                                    text={this.state.inputToShow.text}
                                    closingMethod={this.hideInput}/> : ''
                }
                <div className="game">
                    {   this.state.playingMusic ? 
                        <audio autoPlay controls className="volumMute" onEnded={this.muteSound}>
                            <source src={this.state.musicSrc} type="audio/mpeg" />
                        </audio> : ''
                    }
                    <div className="absolute lifePath">
                        <img src={'./img/lifePath_' + (this.getConfig().useDetours ? 'with' : 'without') + '_traverse.png'} alt="" />
                    </div>
                    <div aria-hidden="true" id="avatar" className={'absolute ' + this.state.avatarPosition}>
                        <img src="./img/avatar.png" alt="avatar" />
                    </div>

                    <div role="region" aria-label="Objectifs">
                    <button tabIndex={this.state.isGameHidden ? -1 : 1} id="flag1" ref={lnk => this.start = lnk} aria-label={'Situation de départ ' + this.state.story[0]} className="absolute flag" onClick={(evt) => this.addStory(0, evt.target)} style={{opacity: this.state.story[0] ? 1 : 0.4}}>
                        <img src="./img/flag1.png" alt="Situation de départ"  />
                    </button>
                    <button tabIndex={this.state.isGameHidden ? -1 : 1} id="flag2" ref={lnk => this.end = lnk} aria-label={'Etat désiré ' + this.state.story[1]} className="absolute flag" onClick={(evt) => this.addStory(1, evt.target)} style={{opacity: this.state.story[1] ? 1 : 0.4}}>
                        <img src="./img/flag2.png" alt="Etat désiré" />
                    </button>
                    <button tabIndex={this.state.isGameHidden ? -1 : 1} className="absolute secondary" aria-label={'Bénéfices secondaires' + this.state.story[2]} ref={lnk => this.secondary = lnk} onClick={(evt) => this.addStory(2, evt.target)} style={{opacity: this.state.story[2] ? 1 : 0.4}} />
                    {
                        this.getConfig().useAttitudes ? 
                        this.getScraZami('attitude', this.state.selectedAttitude, Attitudes, "Attitude", 0) : ''
                    }
                    </div>
                    {
                        this.getObstacles()
                    }

                    {
                        this.state.savingGame ?
                            <SaveGamePrompt saveGame={this.saveGameInternal} closingMethod={this.hideSaveGameDialog}/> : ''
                    }
                    <nav>
                        <button tabIndex={this.state.isGameHidden ? -1 : 1} className="absolute buttonBack" onClick={this.props.backAction}>&lt; Retour</button>
                        <button tabIndex={this.state.isGameHidden ? -1 : 1} className="absolute buttonSave" ref={lnk => this.saveGame = lnk} onClick={(evt) => this.displaySaveGameDialog(evt.target)}>Enregistrer</button>
                        <button tabIndex={this.state.isGameHidden ? -1 : 1} className="absolute buttonNext" ref={lnk => this.next = lnk} disabled={this.state.gameState < this.Enums.gameStatus.started ||
                                            this.state.gameState === this.Enums.gameStatus.finished}
                                            onClick={this.nextStep}>Suivant</button>
                        <button tabIndex={this.state.isGameHidden ? -1 : 1} ref={lnk => this.cancel = lnk} className="absolute buttonPrev" disabled={pastStates.length === 0}
                                            onClick={this.prevStep}>Annuler</button>
                    </nav>
                </div>
                </div>
            </div>
        );
    }
}