import React from 'react';

const Footer = (props) => {
    return (
        <div className="footer">
            <div>© ScraZamiLife 2019 | Réalisé par Laurent Taymans avec le soutien de la fondation NIF, L'ASBL Impros-J'Eux et SocialWare</div>
            <div className="footerImages">
                <img src={'img/logos/LogoScraZamiLife.png'} alt="ScraZami Life"/>
                <img src={'img/logos/logoImprosScraZami2.png'} alt="Impros ScraZami"/>
                <a href="http://www.fondation-nif.com" target="_new"><img src={'img/logos/FondationNIF.png'} alt="Fondation NIF"/></a>
                <a href="https://www.impros-jeux.be" target="_new"><img src={'img/logos/logo_ImprosJeux2.png'} alt="Impros-J'Eux"/></a>
                <a href="https://www.aviq.be" target="_new"><img src={'img/logos/LOGOAVIQ.png'} alt="AVIQ"/></a>
                <a href="https://www.socialware.be/fr" target="_new"><img src={'img/logos/socialware.gif'} alt="SocialWare"/></a>
            </div>
        </div>
    );
}

export default Footer;