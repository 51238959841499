import React from 'react';
import SubTitle from '../Components/SubTitle';
import SelectionPanel from '../Components/SelectionPanel';

const ConfirmationPrompt = (props) => {
    return(
        <SelectionPanel onClick={props.cancel}>
            <div role="document" aria-label={props.title} className="confirmationPrompt">
                <SubTitle text={props.title} />
                <div style={{height: '50%', padding: '1em'}}>
                    {props.question} "{props.name}" ?
                </div>
                <div className="confirmButtons">
                    <button onClick={props.confirmDelete}>Oui</button>
                    <button onClick={props.cancel}>Non</button>
                </div>
                {props.errorMessage ?
                    <div className="centeredText">
                        <span className="error">{props.errorMessage}</span>
                    </div> : ''}
            </div>
        </SelectionPanel>
    );
}

export default ConfirmationPrompt;