import React, { Component } from 'react';
import _ from 'lodash';
import Select from 'react-select';
import ContentTitle from '../Components/ContentTitle';
import ConfirmationPrompt from '../Components/ConfirmationPrompt';
import {loadGamesList, deleteGame} from '../Communication/Api';

const ListItem = (props) => {
    return (
        <div role="region" aria-label={props.game.name} className="saveItem" tabIndex={props.isMainHidden ? -1 : 1}>
            <div style={{marginBottom: '1em'}}>
                <span>Nom: {props.game.name}</span>
                <button aria-label={'Charger ' + props.game.name} tabIndex={props.isMainHidden ? -1 : 1} onClick={props.loadGame} className="loadButton"><i className="material-icons" aria-hidden="true">get_app</i></button>
                <button aria-label={'Supprimer ' + props.game.name} tabIndex={props.isMainHidden ? -1 : 1} onClick={props.deleteButtonClicked} className="deleteButton"><i className="material-icons"  aria-hidden="true">delete_forever</i></button>
            </div>
            <span>Lieu: {props.game.place}</span>&nbsp;
            <span style={{float: 'right'}}>Date: {new Date(parseInt(props.game.date)).toLocaleString()}</span>
            <div>Participants: {
                props.game.participants.map((participant, index, table) => 
                <span key={`part${index}`}>{participant.part_name}{index < table.length - 1 ? ', ' : ''}</span>)
            }</div>
            <div>{props.game.description}</div>
        </div>
    );
}

export default class Old extends Component{
    constructor(props){
        super(props);
        this.state = {
            gamesList: [],
            filteredList: [],
            viewingPage: 0,
            itemsByPage: 10,
            placesList: [],
            participantsList: [],
            errorMessage: '',
            isMainHidden: false
        };
        this.filterByPlace = this.filterByPlace.bind(this);
        this.filterByParticipant = this.filterByParticipant.bind(this);
        this.applyFilters = this.applyFilters.bind(this);
        this.deleteButtonClicked = this.deleteButtonClicked.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    getGamesList(){
        loadGamesList(this.props.user.username, this.props.user.key).then((gamesList) => {
            _.sortBy(gamesList, ['date', 'name']);
            const placesOptions = _.map(_.uniq(_.map(gamesList, 'place')), (place, index) => {
                return {value: index, label: place};
            });
            const participanOptions = _.map(_.uniq(_.flatten(_.map(gamesList, (game) => {
                let parts = [];
                _.each(game.participants, (part) => {
                    parts.push(part.part_name.toLowerCase());
                });
                return parts;
            }))), (part, index) => {
                return {value: index, label: part};
            });
            this.setState({
                gamesList: gamesList,
                filteredList: gamesList,
                viewingPage: 0,
                placesFilter: [],
                participantsFilter: [],
                deleting: -1,
                placesList: placesOptions,
                participantsList: participanOptions
            });
        }).catch((error) => {

        });
    }

    componentDidMount(){
        this.getGamesList();
    }

    deleteButtonClicked(event, id){
        event.stopPropagation();
        this.setState({
            deleting: id,
            isMainHidden: true
        });
    }

    confirmDelete(){
        if(this.state.deleting > -1){
            deleteGame(this.props.user.username, this.props.user.key, this.state.filteredList[this.state.deleting].id)
            .then(() => {
                this.setState({
                    deleting: -1,
                    errorMessage: '',
                    isMainHidden: false
                }, this.getGamesList);
            })
            .catch((error) => {
                this.setState({
                    errorMessage: error
                });
            });
        }
    }

    cancel(){
        this.setState({
            deleting: -1,
            isMainHidden: false
        });
    }

    applyFilters(){
        let filteredList = Object.assign([], this.state.gamesList);
        if(this.state.placesFilter.length > 0){
            const names = _.map(this.state.placesFilter, 'label');
            filteredList = filteredList.filter(game => names.indexOf(game.place) > -1);
        }
        if(this.state.participantsFilter.length > 0){
            const names = _.map(this.state.participantsFilter, 'label');
            let games = [];
            _.each(filteredList, (game) => {
                if(_.some(game.participants, (part) => {
                    return _.some(names, (name) => {
                        return name.toUpperCase() === part.part_name.toUpperCase();
                    });
                })){
                    games.push(game);
                }
            });
            filteredList = games;
        }
        this.setState({
            filteredList: filteredList
        });
    }

    filterByPlace(places){
        this.setState({
            placesFilter: places
        }, this.applyFilters);
    }

    filterByParticipant(parts){
        this.setState({
            participantsFilter: parts
        }, this.applyFilters);
    }

    render() {
        return (
            <div className="content reduced">
                <ContentTitle text="Charger une partie"/>
                
                {
                    this.state.deleting > -1 ? 
                        <ConfirmationPrompt   title={'Supprimer la sauvegarde ?'}
                                            question={'Êtes-vous sûr de vouloir supprimer la sauvgarde'}
                                            name={this.state.filteredList[this.state.deleting].name}
                                            confirmDelete={this.confirmDelete}
                                            cancel={this.cancel}
                                            errorMessage={this.state.errorMessage}/> : ''
                }
                <div className="selectorContainer positionedSelector">
                    {
                        this.state.placesList.length > 0 ?
                            <div className="selector">
                                <div className="selectorLabel">Lieu: </div>
                                <div className="multiSelector">
                                    <Select isMulti={true} 
                                            isClearable={true} 
                                            options={this.state.placesList}
                                            placeholder="Sélectionner..."
                                            noOptionsMessage={() => "Pas d'autres options"}
                                            value={this.state.placesFilter} 
                                            onChange={this.filterByPlace}
                                            aria-label="Filtrer les lieux"
                                            tabIndex={this.state.isMainHidden ? -1 : 1} />
                                </div>
                            </div>
                            : ''
                    }
                    {
                        this.state.participantsList.length > 0 ?
                            <div className="selector">
                                <div className="selectorLabel">Participants: </div>
                                <div className="multiSelector">
                                    <Select isClearable={true}
                                            isMulti={true}
                                            options={this.state.participantsList}
                                            value={this.state.participantsFilter}
                                            placeholder="Sélectionner..."
                                            noOptionsMessage="Pas d'autres options"
                                            onChange={this.filterByParticipant}
                                            aria-label="Filtrer les participants"
                                            tabIndex={this.state.isMainHidden ? -1 : 1} />
                                </div>
                            </div>
                            : ''
                    }
                </div>
                <div className="savedContent">
                {
                    this.state.filteredList.length > 0 ?
                    <div className="savesGamesList">
                    {
                        this.state.filteredList.map((game, index) =>
                            <ListItem key={game.name} 
                                        loadGame={() => this.props.loadGame(game)} 
                                        game={game} 
                                        deleteButtonClicked={(event) => this.deleteButtonClicked(event, index)}
                                        isMainHidden={this.state.isMainHidden}/>
                        )
                    }
                    </div> :
                    <div className="savesGamesList">
                        <h2>Aucune partie trouvée</h2>
                    </div>
                }
                </div>
                <nav>
                    <button tabIndex={this.state.isGameHidden ? -1 : 1} className="absolute buttonBack" onClick={this.props.backAction}>&lt; Retour</button>
                </nav>
            </div>
        );
    }
}