import React, {Component} from 'react';
import SubTitle from '../Components/SubTitle';
import SelectionPanel from '../Components/SelectionPanel';
import _ from 'lodash';

export default class CitationChooser extends Component{
    constructor(props){
        super(props);
        this.state = {
            selectedCard: -1,
            cardsList: props.cardsList
        };
        this.selectCard = this.selectCard.bind(this);
        this.selectRandom = this.selectRandom.bind(this);
        this.confirm = this.confirm.bind(this);
        this.search = this.search.bind(this);
        this.sendCard = this.sendCard.bind(this);
        this.setSelectedCard = this.setSelectedCard.bind(this);
    }

    componentDidMount(){
        this.setState({
            selectedCard: -1
        }, this.nameInput.focus());
    }

    search(event){
        const newCardList = _.filter(this.props.cardsList, (card) => {
            return card.citation.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1 ||
                    card.author.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1;
        });
        let selectedCard = this.state.selectedCard;
        if(newCardList.length === 1){
            selectedCard = this.props.cardsList.indexOf(newCardList[0]);
        }

        this.setState({cardsList: newCardList, selectedCard: selectedCard});
    }

    sendCard(idx){
        this.props.chooseCitation(this.props.selectedCardsIndex,
            idx,
            this.props.closingMethod);
    }

    confirm(event){
        if(event.charCode === 13 && this.state.cardsList.length > 0){
            const idx = this.props.cardsList.indexOf(this.state.cardsList[0]);
            this.sendCard(idx);
        }
    }

    setSelectedCard(idx){
        this.setState({selectedCard: idx}, () => {
            this.ttl.focus();
        });
    }

    selectCard(cit){
        const idx = _.findIndex(this.props.cardsList, (card) => card.citation === cit);
        this.setSelectedCard(idx);
    }

    selectRandom(){
        const max = this.props.cardsList.length;
        const rnd = Math.floor(Math.random() * max);
        this.setSelectedCard(rnd);
    }

    truncate(str, n, useWordBoundary ){
        var strCut = str.substr(2, str.length - 4);
        if (strCut.length <= n) { return strCut; }
        var subString = strCut.substr(0, n-1);
        return (useWordBoundary 
           ? subString.substr(0, subString.lastIndexOf(' ')) 
           : subString) + "...";
    };

    render(){
        return(
            <SelectionPanel onClick={this.props.closingMethod}>
                <div role="document" id="dialog" aria-label={this.props.title} className="cardChooser">
                    <SubTitle text={this.props.title}/>
                    <div className="chooserContainer">
                    <input type="text" placeholder="Rechercher..." 
                        style={{marginBottom:0}}
                        onChange={this.search}
                        onKeyPress={this.confirm}
                        ref={(input) => {this.nameInput = input;}}/>
                    <button onClick={this.selectRandom}>Choisir au hasard</button>
                    {
                        this.state.cardsList.map((card, index) => {
                            return(
                                <div className="citBigCard" index={index} key={index} 
                                onClick={() => this.selectCard(card.citation)}>
                                    <div>{this.truncate(card.citation, 60, true)}</div>
                                </div>);
                        })
                    }
                    </div>
                    <div className="descriptionContainer">
                        {
                            this.state.selectedCard > -1 ?
                            <div>
                                <div className="hugeCardContainer">
                                    <div className="hugeCard">
                                        <img src="./img/zamis/zamisage.jpg" alt="" />
                                    </div>
                                </div>
                                <div className="cardDescription">{this.props.cardsList[this.state.selectedCard].citation}</div>
                                <div> - {this.props.cardsList[this.state.selectedCard].author}</div>
                            </div> : ''
                        }
                    </div>
                    {
                        this.state.selectedCard > -1 ?
                        <div className="buttonChoiceContainer">
                            <button ref={(ttl) => {this.ttl = ttl;}}
                                    onClick={() => {this.props.chooseCitation(this.props.selectedCardsIndex,
                                                                        this.state.selectedCard,
                                                                        this.props.closingMethod)}}>Ajouter</button>
                        </div> : ''
                    }
                </div>
            </SelectionPanel>
        );
    }
}