import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ContentTitle from '../Components/ContentTitle';
import Footer from '../Components/Footer';

export default class Welcome extends Component {

    render(){
        return(
            <div className="content">
                <ContentTitle text={'Bienvenue' + (this.props.user ?(' ' + this.props.user.fullname) : '')} />
                <div className="mainMenu contentZone">
                    <div>
                        <Link innerRef={lnk => this.exercises = lnk} className="linkButton" to="/intro">Exercice</Link>
                    </div>
                    <div>
                        <Link innerRef={lnk => this.newGame = lnk} className="linkButton" to="/new">Nouvelle partie</Link>
                    </div>
                    <div>
                        <Link innerRef={lnk => this.loadGame = lnk} className="linkButton" to="/old">Charger une partie existante</Link>
                    </div>
                    <div>
                        <Link innerRef={lnk => this.myAccount = lnk} className="linkButton" to="/account">Mon compte</Link>
                    </div>
                    {
                        this.props.user && this.props.user.admin ? 
                        <div>
                            <Link innerRef={lnk => this.administration = lnk} className="linkButton" to="/admin">Administration</Link>
                        </div> : ''
                    }
                    <div>
                        <button className="linkButton" style={{fontSize: 'initial'}} ref={lnk => this.disconnect = lnk} onClick={() => this.props.disconnect(this.props.user.username)}>Se Déconnecter</button>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
};