import React from 'react';

const CardSelector = (props) => {
    const canAddCard = props.selectedCards.length < props.limit;
    const cardClass = props.small ? 'smallCard' : 'card';
    const addCardClass = props.small ? 'addSmallCard' : 'addCard';
    return (
        <div>
            {
                canAddCard ?
                <button tabIndex={props.notFocusable ? -1 : 1} className="selectableElement" onClick={props.addCard} aria-label={props.label}>
                    <div className={addCardClass + ' absolute'}>
                        <div className="plusButton" aria-hidden="true">+</div>
                        <h4>{props.title}</h4>
                    </div>
                </button> : ''
            }
            {
                props.selectedCards.map((cardIdx, index) => {
                    const card = props.cardsList[cardIdx];
                    return(
                    <button tabIndex={props.notFocusable ? -1 : 1} key={card.name + index}  className="selectableElement" aria-label={card.name + ' ' + props.label} onClick={(evt) => props.showCard ? props.showCard(card, evt.target) : null}>
                        <div className={cardClass + ' absolute'}
                        style={{
                        zIndex: 10 - index,
                        left: ((65 * index) + (canAddCard ? 65 : 0)) + 'px',
                        bottom: ((27 * index) + (canAddCard ? 27 : 0)) + 'px'
                    }}>
                            <h4>{card.name}</h4>
                            <img src={'./img/' + card.image} alt="" />
                        </div>
                    </button>);
                })
            }
        </div>
    );
}

export default CardSelector;