const Zamis = [
    {
        name: 'ZamiZen',
        image: 'zamis/zamizen.jpg',
        description: '<p>C’est un personnage très sage, très patient, capable de prendre du recul face à tout évènement grave qui peut arriver sur « la route de la vie » et ceci sans tomber dans un excès d’émotions. Sa « zen attitude » ne provient pas d’une adhésion fidèle à un Dieu particulier, ou à un gourou d’une secte, mais bien d’une sorte de « philosophie de vie » issue de son expérience, de bien des essais et erreurs et d’une bonne connaissance du fonctionnement du cerveau humain. Il s’intéresse à tout et aime lire. Il ne tire aucune gloire, ni argent de cela. Du moins n’en fait pas « commerce » comme le ScraBisnessFric qui aime lui jouer au « Gourou Coach qui a la « bonne méthode » pour vous aider. Le ZamiZen est heureux de vivre « ici et maintenant ». On aime venir lui demander conseil car il est plus qu’un sage : certains disent qu’il est « éveillé », d’autres diront que c’est un « philosophe », un « Grand Sage ».</p>'
    },
    {
        name: 'ZamiGuide',
        image: 'zamis/zamiguide.jpg',
        description: '<p>C’est un personnage qui a de l’expérience car il est déjà passé par les mêmes épreuves	. Il a connu cela, l’a dépassé et peut témoigner en toute humilité. Lui demander conseil fait gagner du temps mais surtout « rassure ». Il fait cela gratuitement. C’est un personnage que l’on retrouve, par exemple, dans beaucoup d’hôpitaux et qui, bénévolement, va témoigner qu’il est possible « de s’en sortir ». Autre exemple : si vous êtes en train de construire votre maison et qu’un ZamiGuide a déjà fait de même, tout naturellement vous irez le trouver pour glaner quelques conseils pratiques.</p>'
    },
    {
        name: 'ZamiCoeur',
        image: 'zamis/zamicoeur.jpg',
        description: '<p>C’est un personnage qui sait prendre le temps de vous écouter. Il ne vous conseille pas (sans quoi il deviendrait un Scra) car il ne fait que vérifier s’il a bien compris ce qui se passe chez vous.  Vous n’entendrez aucunes critiques sortir de sa bouche. Il ne cherche pas à vous culpabiliser. Il ne cherche pas à vous rassurer, comme le ScraMèrePoule (qui cherche à vous sauver) ou le ScraCool (qui banalise vite). Il essaie juste de comprendre ce qui se passe. Il peut alors, dans un second temps, proposer de vous aider, s’il juge cela nécessaire. Mais juste le temps de quelques recherches.  Ce n’est pas un « sauveur » et vous n’êtes pas considéré comme étant une victime.</p><p>Il reformule ce qu’il entend pour mieux vous comprendre mais aussi pour que vous soyez certain que ce que vous dites de triste ou de gai soit bien ce que vous pensez. En faisant cela vous vous sentez compris et cela vous permet de voir plus clair. C’est un témoin que vous « reprenez pied » tout doucement. Vous pouvez vous appuyer dessus pour vous en sortir. Il prendra de vos nouvelles régulièrement.</p>'
    },
    {
        name: 'ZamiPro',
        image: 'zamis/zamipro.jpg',
        description: '<p>C’est un professionnel qui monnaie son savoir, mais avec sagesse. C’est un personnage qui n’est pas avide d’argent et de pouvoir, au point de n’en avoir « jamais assez », comme le ScraBisnessFric. Il fait son travail, honnêtement, pour gagner sa vie et nourrir sa famille. C’est tout. Faire en sorte que sa famille ne manque de rien est important pour lui mais sans se sacrifier au travail. Il sait garder du temps pour se reposer et entretenir son corps. Il sait se ressourcer.  Pour pouvoir bénéficier de ses capacités professionnelles il faut aller le trouver. Lui ne viendra pas vers vous pour vous bombarder de son « savoir ». La seule chose qu’il pourrait faire, en début de sa carrière professionnelle, c’est de laisser trainer, comme le font tous les professionnels, des flyers et sa carte de visite. Mais jamais il ne vous harcèlera. Il ne cherche pas à vous vendre « le bon plan, la bonne affaire, le bon produit ». Vous devrez aller le trouver.  Quand vous lui demanderai conseil il vous écoutera. Il sera attentif à bien vous rejoindre dans ce que vous désirez.</p>'
    },
    {
        name: 'ZamiMage',
        image: 'zamis/zamimage.jpg',
        description: '<p>La magie n’est pas toujours présente dans le jeu du ScraZami. C’est pourquoi, en début du jeu, le maître du jeu demande si on joue avec cette carte.  Si c’est oui, la carte ZamiMage reste dans le tas des cartes Zamis. Si c’est non, on enlève la carte.</p><p>        C’est un personnage qui a un tas de pouvoirs magiques et qui peut les transmettre au disciple de son choix. Le ZamiMage est une sorte de magicien, ou de « génie » comme on peut en voir dans des films et dessins animés populaires. Il manipule les événements comme il le désire et peut même voyager dans le temps. Certains peuvent même prédire l’avenir. Si la carte ZamiMage est activée dans le jeu le ScraMagouille a automatiquement les mêmes pouvoirs que ce mage et il peut s’en suivre biens des batailles entre eux.</p>'
    },
    {
        name: 'ZamiSage',
        image: 'zamis/zamisage.png',
        description: '<p>C’est un personnage qui ressemble fortement au ZamiZen, mais il est plus proche du héros de l’histoire. Cela peut être un parent, un papy, une mamy, une voisine sympa ou un écrivain. Cette personne a vécu aussi beaucoup de choses dans la vie et a connu biens des échecs mais s’est toujours relevée. Ce personnage n’a pas nécessairement vécu ce que vous êtes en train de vivre, mais sa sagesse lui permet de « prendre du recul » et vous renvoyer des paroles apaisantes et pleines d’espoir.</p><p>Ce personnage aime les citations et sait les placer avec justesse. Dans le jeu on tire alors une « carte citation », au hasard, pour aider le ZamiSage ou redonner espoir au héros.  Le ZamiSage ne fait rien à votre place non plus. Il vous écoute, vous permet de voir la situation d’un peu plus haut. Grâce à lui vous prenez un peu de recul. Le ZamiSage peut utiliser le jeu © Le ScraZami pour vous aider.</p>'
    },
    {
        name: 'ZamiChance',
        image: 'zamis/zamichance.jpg',
        description: '<p>Ce n’est pas un « personnage », c’est une sorte de « cadeau du ciel » qui nous tombe dessus. Dans le cas présent cela « tombe à point nommé pour le héros ». Cette carte peut apparaitre à tout moment de la création de l’histoire. C’est le « billet gagnant » au Loto, la personne qui arrive au bon moment pour nous conduire là où nous voulons aller, etc. Mais cela peut être aussi la voiture qui tombe en panne alors que l’on voulait aller faire un tour. Panne providentielle car le pont que nous voulions emprunter s’est effondré en tuant des milliers de personnes. C’était une « chance » d’être resté chez soi. Etc.</p>'
    }
];

export default Zamis;