import React, { Component } from 'react';
import _ from 'lodash';
import ContentTitle from '../Components/ContentTitle';
import SubTitle from '../Components/SubTitle';
import ConfirmationPrompt from '../Components/ConfirmationPrompt';
import SelectionPanel from '../Components/SelectionPanel';
import {getUsersList, deleteUser, saveUser} from '../Communication/Api';
import User from '../Model/User';

class UserForm extends Component{
    constructor(props){
        super(props);
        this.state = {
            username: props.user.username,
            name: props.user.fullname,
            pass: '',
            admin: props.user.admin,
            errorMessage: ''
        }

        this.saveUserClicked = this.saveUserClicked.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleAdminChange = this.handleAdminChange.bind(this);
        this.handleLoginChange = this.handleLoginChange.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    componentDidMount(){
        this.nameInput.focus();
    }

    handleNameChange = (evt) => {
        this.setState({
            name: evt.target.value,
            errorMessage: ''
        });
    }

    handlePasswordChange = (evt) => {
        this.setState({
            pass: evt.target.value,
            errorMessage: ''
        });
    }

    handleAdminChange = (evt) => {
        this.setState({
            admin: evt.target.checked,
            errorMessage: ''
        });
    }

    handleLoginChange = (evt) => {
        this.setState({
            username: evt.target.value,
            errorMessage: ''
        });
    }

    saveUserClicked = (evt) => {
        evt.preventDefault();
        if(this.props.user.id === -1 && (this.state.pass === '' || this.state.username === '' || this.state.username.length < 2)){
            this.setState({
                errorMessage: 'Erreur: Données incomplètes'
            }, () => this.errorField.focus());
        } else {
            const user = new User(this.props.user.id, this.state.username, this.state.name, this.state.admin, '', this.state.pass);
            saveUser(this.props.username, this.props.userKey, user)
            .then(()=>{
                this.props.closePanel();
            })
            .catch((message) => {
                this.setState({
                    errorMessage: message
                }, () => this.errorField.focus());
            });
        }
    }

    cancel = (evt) => {
        evt.preventDefault();
        this.props.cancel();
    }

    render(){
        return (
        <SelectionPanel onClick={this.cancel}>
            <div role="document" aria-label="Formulaire utilisateur" className="inputShower">
                <SubTitle text={this.state.username}/>
                <div>
                    <form className="userForm" onSubmit={this.saveUserClicked}>
                        <div>Login</div>
                        <input type="text" onChange={this.handleLoginChange} value={this.state.username} placeholder="Login" disabled={this.props.user.id > -1} ref={(input) => {this.nameInput = input;}}/>
                        <div>Nom complet</div>
                        <input type="text" onChange={this.handleNameChange} value={this.state.name} placeholder="Nom complet" />
                        <div>Mot de passe</div>
                        <input type="password" onChange={this.handlePasswordChange} value={this.state.pass} placeholder="Mot de passe" />
                        <input name="admin" id="admin" type="checkbox" onChange={this.handleAdminChange} checked={this.state.admin}/><label for="admin">Administrateur</label><br/>
                        <input type="submit" value="Enregistrer" onClick={this.saveUserClicked} style={{marginBottom:'2em'}}/>&emsp;
                        <button className="cancelButton" onClick={this.cancel}>Annuler</button>
                        {this.state.errorMessage !== '' ?
                        <div className="centeredText" tabIndex="1" ref={(error) => {this.errorField = error}}>
                            <span className="error">{this.state.errorMessage}</span>
                        </div> : ''}
                    </form>
                </div>
            </div>
        </SelectionPanel>
        );
    }
}

export default class Admin extends Component{
    constructor(props){
        super(props);
        this.state = {
            userList: [],
            editedUser: null,
            deletingUser: null,
            errorMessage: '',
            isMainHidden: false
        };

        this.updateUserList = this.updateUserList.bind(this);
        this.deleteButtonClicked = this.deleteButtonClicked.bind(this);
        this.userClicked = this.userClicked.bind(this);
        this.newUserClicked = this.newUserClicked.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.closePanel = this.closePanel.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    componentDidMount(){
        if(this.props.user){
            this.updateUserList();
        }
    }

    componentDidUpdate(prevProps){
        if(!_.isEqual(prevProps.user, this.props.user) && this.props.user){
            this.updateUserList();
        }
    }

    updateUserList(){
        getUsersList(this.props.user.username, this.props.user.key).then((userList) => {
            if(userList){
                const userArray = _.map(userList, userDto => {
                        return new User(
                        userDto.id,
                        userDto.user_login,
                        userDto.user_name,
                        userDto.administrator,
                        userDto.log_key
                        );
                    }
                );
                this.setState({
                    userList: userArray
                });
            }
        }).catch(() => {
            console.log('no user found');
        });
    }

    deleteButtonClicked(user){
        this.setState({
            deletingUser: user,
            isMainHidden: true
        });
    }

    userClicked(user){
        this.setState({
            editedUser: user,
            isMainHidden: true
        });
    }

    newUserClicked(){
        const user = new User(-1, '', '', false, '', '');
        this.setState({
            editedUser: user,
            isMainHidden: true
        });
    }

    confirmDelete(){
        deleteUser(this.props.user.username, this.props.user.key, this.state.deletingUser.id)
        .then(() => {
            this.closePanel();
        })
        .catch((message) => {
            this.setState({
                errorMessage: message
            })
        });
    }

    closePanel(){
        this.setState({
            deletingUser: null,
            editedUser: null,
            isMainHidden: false
        }, this.updateUserList);
    }

    cancel(){
        this.setState({
            deletingUser: null,
            editedUser: null,
            isMainHidden: false
        });
    }

    render() {
        return (
            <div className="content reduced">
                <ContentTitle text="Administration" />
                <div className="">
                {
                    this.state.editedUser !== null ?
                        <UserForm user={this.state.editedUser} 
                                    closePanel={this.closePanel}
                                    username={this.props.user.username}
                                    userKey={this.props.user.key}
                                    cancel={this.cancel}/> : ''
                }
                {
                    this.state.deletingUser !== null ?
                        <ConfirmationPrompt   title={'Supprimer '+ this.state.deletingUser.username +' ?'}
                                            question={'Êtes-vous sûr de vouloir supprimer l\'utilisateur'}
                                            name={this.state.deletingUser.username}
                                            confirmDelete={this.confirmDelete}
                                            cancel={this.cancel}
                                            errorMessage={this.state.errorMessage} /> : ''
                }
                <div className="selectorContainer positionedSelector">
                    <button tabIndex={this.state.isMainHidden ? -1 : 1} onClick={this.newUserClicked}>Ajouter...</button>
                </div>
                <div className="savedContent">
                <div className="savesGamesList">
                    {
                        this.state.userList.map((user) =>
                        <div role="region" aria-label={'Utilisateur ' + user.username} tabIndex={this.state.isMainHidden ? -1 : 1} key={user.id} className="saveItem">
                            <div>
                                <span>Login: {user.username}</span>
                                {
                                    user.username !== this.props.user.username ?
                                    <div>
                                        <button aria-label={'Supprimer ' + user.username} tabIndex={this.state.isMainHidden ? -1 : 1} onClick={() => {this.deleteButtonClicked(user)}} className="deleteButton"><i className="material-icons" aria-hidden="true">delete_forever</i></button>
                                        <button aria-label={'Modifier ' + user.username} tabIndex={this.state.isMainHidden ? -1 : 1} onClick={() => {this.userClicked(user)}} className="modifyButton"><i className="material-icons" aria-hidden="true">edit</i></button>
                                    </div> : ''
                                }
                            </div>
                            <div>
                                <span>Nom: {user.fullname}</span>
                            </div>
                            <div>
                                <span>Administrateur:
                                    <i className="material-icons">
                                        {user.admin ? 'check_box' : 'check_box_outline_blank'}
                                    </i></span>
                            </div>
                        </div>
                        )
                    }
                </div>
                </div>
                <nav>
                    <button tabIndex={this.state.isMainHidden ? -1 : 1} className="absolute buttonBack" onClick={this.props.backAction}>&lt; Retour</button>
                </nav>
                </div>
            </div>
        );
    }
}