import React from 'react';
import SubTitle from '../Components/SubTitle';
import SelectionPanel from '../Components/SelectionPanel';

const CitationShower = (props) => {
    return(
        <SelectionPanel onClick={props.closingMethod}>
            <div role="document" aria-label="Citation" className="cardShower">
                <SubTitle text="Citation" />
                <div className="descriptionContainer">
                    <div>
                        <div className="hugeCardContainer">
                            <div className="hugeCard">
                                <img src="./img/zamis/zamisage.jpg" alt="" />
                            </div>
                        </div>
                        <div tabIndex="1" className="cardDescription">{props.citation.citation}</div>
                        <div tabIndex="1">{props.citation.author}</div>
                    </div>
                </div>
                <div className="buttonChoiceContainer">
                    <button aria-label="Supprimer la citation"
                            onClick={props.deleteCard}>
                        Supprimer la citation
                    </button>
                    <button aria-label='Fermer'
                            onClick={props.closingMethod}>
                        Fermer
                    </button>
                </div>
            </div>
        </SelectionPanel>
    );
}

export default CitationShower;