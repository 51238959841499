import React, { Component } from 'react';
import ContentTitle from '../Components/ContentTitle';
import {saveUser} from '../Communication/Api';
import User from '../Model/User';

export default class Account extends Component{
    constructor(props){
        super(props);
        this.state = {
            name: '',
            password: '',
            repeatPass: '',
            error: false,
            success: false,
            errorMessage: ''
        };

        this.saveUserClicked = this.saveUserClicked.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handlePassChange = this.handlePassChange.bind(this);
        this.handleRepeatChange = this.handleRepeatChange.bind(this);
    }

    componentDidMount(){
        if(this.props.user){
            this.setState({
                name: this.props.user.fullname
            });
        }
    }

    isPasswordValid(pass){
        const reg = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/);
        if(reg.test(pass)){
            return true;
        }
        return false;
    }

    saveUserClicked(evt){
        evt.preventDefault();
        if((this.state.password.length === 0 || this.isPasswordValid(this.state.password)) && this.state.password === this.state.repeatPass){
            const user = new User(
                this.props.user.id,
                this.props.user.username,
                this.state.name,
                this.props.user.admin,
                '',
                this.state.password
            )
            saveUser(this.props.user.username, this.props.user.key, user).then(() => {
                this.setState({
                    success: true,
                    error: false
                }, () => {this.props.updateUserFullName(this.state.name)});
            }).catch((message)=> {
                this.setState({
                    success: false,
                    error: true,
                    errorMessage: message
                });
            });
        } else {
            this.setState({
                success: false,
                error: true,
                errorMessage: 'Mot de passe incorrect.'
            });
        }
    }

    handleNameChange(evt){
        this.setState({
            name: evt.target.value,
            success: false,
            error: false
        });
    }

    handlePassChange(evt){
        this.setState({
            password: evt.target.value,
            success: false,
            error: false
        });
    }

    handleRepeatChange(evt){
        this.setState({
            repeatPass: evt.target.value,
            success: false,
            error: false
        });
    }

    render() {
        return (
            <div className="content reduced">
                <ContentTitle text="Mon Compte"/>
                <div className="">
                    <form className="userModify" onSubmit={this.saveUserClicked}>
                        <div>Nom complet</div>
                        <input type="text" onChange={this.handleNameChange} value={this.state.name} placeholder="Nom complet" />
                        <div>Mot de passe</div>
                        <div>Le mot de passe doit contenir au moins 8 caractères dont au moins une lettre minuscule, une majuscule et un chiffre</div>
                        <input className={this.state.error ? 'borderError': ''} type="password" onChange={this.handlePassChange} value={this.state.password} placeholder="Mot de passe" />
                        <div>Répéter le mot de passe</div>
                        <input className={this.state.error ? 'borderError': ''} type="password" onChange={this.handleRepeatChange} value={this.state.repeatPass} placeholder="Répéter le mot de passe" />
                        <input type="submit" value="Enregistrer" onClick={this.saveUserClicked}/>
                    </form>
                    {
                        this.state.success ?
                        <div className="centeredText">
                            <span className="success">Modifications enregistrées avec succès</span>
                        </div> : (this.state.error ?
                        <div className="centeredText">
                            <span className="error">{this.state.errorMessage}</span>
                        </div> : '')
                    }
                </div>
                <nav>
                    <button tabIndex={this.state.isGameHidden ? -1 : 1} className="absolute buttonBack" onClick={this.props.backAction}>&lt; Retour</button>
                </nav>
            </div>
        );
    }
}