import React from 'react';
const ContentTitle = (props) => {
    return (
        <div className="titleZone">
        <p className="contentTitle">
            <svg xmlns="http://www.w3.org/2000/svg">
                <text textAnchor="middle" x="50%" y="50">{props.text}</text>
            </svg>
        </p>
        <h1>{props.text}</h1>
    </div>
    );
}

export default ContentTitle;