const Citations = [{
    citation: '« J’ai appris que l’on pouvait presque toujours voir le bon côté des choses, pourvu que l’on prenne la décision de le faire ».',
    author: 'Lucy Maud Montgomery'
}, {
    citation: '« L’inquiétude ne protège pas des chagrins à venir ; elle ne fait qu’éclipser la joie du moment présent».',
    author: 'Leo Buscaglia'
}, {
    citation: '« Le bonheur n’est pas le but, mais le moyen de la vie ».',
    author: 'Paul Claudel'
}, {
    citation: '« Je crois en une contamination de l’amour, de la bienveillance, de la douceur et de l’intelligence. Chaque fois qu’on pose un acte de tendresse, d’affection, d’amour, chaque fois qu’on éclaire quelqu’un en lui donnant un conseil, on modifie un tout petit peu l’avenir de l’humanité dans le bon sens. Et chaque fois qu’on dit une vacherie, qu’on commet une méchanceté, et qu’on les répète, on fait perdre du temps aux progrès humains. Que chacun cultive le plus grand nombre possible de ressentis et d’actes positifs est donc vital pour tout le monde. ».',
    author: 'Christophe André'
}, {
    citation: '« Si vous ne dites pas à quelqu’un que vous l’appréciez, voire, que vous l’aimez, c’est comme emballer son cadeau et ne pas lui offrir ».',
    author: 'Un ZamiSage'
}, {
    citation: '« Choisir que le droit de choisir sa voie est un privilège sacré. Servez-vous-en. Habitez le possible ».',
    author: 'Oprah Winfrey'
}, {
    citation: '« Plus vous serez bon avec vous-même, plus la bonté sera votre réaction spontanée envers autrui».',
    author: 'Wayne Dyer'
}, {
    citation: '« Tout un chacun peut se mettre en colère. C’est une chose facile. Mais être en colère contre la juste personne, au juste degré, au juste moment, pour une juste raison et de la juste manière, voilà qui l’est moins ».',
    author: 'Aristote'
}, {
    citation: '« Ceux qui demeurent parmi les beautés et les mystères du monde ne sont jamais ni seuls ni las de la vie »',
    author: 'Rachel Carson'
}, {
    citation: '« Dans la vie, il y en a largement pour tout le monde. Avoir la « mentalité de l’abondance » c’est savoir partager le prestige, la reconnaissance professionnelle, les bénéfices et la prise de décision. C’est aussi se ménager des potentialités, des occasions, des choix, une part de créativité ».',
    author: 'Stephen Covey'
}, {
    citation: '« Ce doit être un jeu pour vous: voyez le côté positif en toutes circonstances. Vos réactions affectives sont à 95% déterminées par votre propre interprétation des faits ».',
    author: 'Brian Tracy'
}, {
    citation: '« Un voyage de mille lieues commence toujours par un premier pas ».',
    author: 'Lao Tseu'
}, {
    citation: '« Un être sans humour est comme une charrette sans ressorts. Il se fait secouer à chaque caillou sur le chemin ».',
    author: 'Henri Ward Beecher'
}, {
    citation: '« Les êtres humains préfèrent aller à leur perte plutôt que de changer leurs habitudes »',
    author: 'Léon Tolstoï'
}, {
    citation: '« La vie est un mystère qu’il faut vivre et non un problème à résoudre ».',
    author: 'Gandhi.'
}, {
    citation: '« Si tu veux voler, tu dois te libérer de tout ce qui te pèse ».',
    author: 'Le ZamiSage'
}, {
    citation: '« Faites des bêtises, mais faites-les avec enthousiasme ».',
    author: 'Colette'
}, {
    citation: '« Ce sont les capacités qui comptent, et non les incapacités ».',
    author: 'Peter Drucker'
}, {
    citation: '« Les mots ont le pouvoir de détruire ou de soigner; lorsqu’ils sont justes et généreux ils peuvent changer le monde ».',
    author: 'Bouddha'
}, {
    citation: '« Je préfère vivre en optimiste et me tromper, que vivre en pessimiste pour la seule satisfaction d’avoir raison ».',
    author: 'Milan Kundera'
}, {
    citation: '« Dès l’instant où vous aurez foi en vous-même, vous saurez comment vivre ».',
    author: 'Johann Wolfgang Von Goethe',
    
}, {
    citation: '« Si la vie ne réalise pas tous nos rêves, c\'est qu\'elle a bien mieux en réserve ».',
    author: 'A. Bennani'
}, {
    citation: '« Quand tout semble être contre vous, souvenez-vous que l’avions décolle face au vent, et non avec lui. »',
    author: 'Henry Ford'
}, {
    citation: '« Quoi que vous pensiez ou croyez pouvoir faire, faites-le. L’action porte en elle la magie, la grâce et le pouvoir »',
    author: 'Goethe'
}, {
    citation: '« Vous pouvez blâmer les gens qui se cognent dans l’obscurité ou vous pouvez allumer des bougies. La seule erreur est d’avoir conscience d’un problème en choisissant de ne pas agir ».',
    author: 'Paul HawkenFord'
}, {
    citation: '« Un bon remède contre la morosité : partager notre gaité ! ».',
    author: 'A. Bennani'
}, {
    citation: '« N’ayez pas peur d’être différent. Ayez peur d’être pareil que tout le monde ».',
    author: 'Le Zamisage.'
}, {
    citation: '« Concentre-toi sur le chemin que tu as à parcourir, ne regrette pas ce que tu as vécu. Toutes les expériences et les cicatrices ont fait de toi la personne que tu es ».',
    author: 'Le Zamisage.'
}, {
    citation: '« Il est plus facile de professer en paroles un humanisme de bon aloi, que de rendre service à son voisin de palier ».',
    author: 'Henri Laborit'
}, {
    citation: '« Parfois, la manière la plus rapide de commencer à faire ce qui marche pour vous est d’arrêter de faire ce qui ne marche pas ».',
    author: 'Jeffrey Allen'
}, {
    citation: '« Le but de la vie est le développement personnel. Parvenir à une parfaite réalisation de sa nature. C’est pour cela que nous sommes tous ici ».',
    author: 'Oscar Wilde Aphorismes.'
}, {
    citation: '« Nous portons en nous des merveilles que nous cherchons en dehors de nous ».',
    author: 'Sir Thomas Browne'
}, {
    citation: '« L’attitude de l’homme envers sa vie détermine sa destinée ».',
    author: 'Albert Schweitzer'
}, {
    citation: '« Si vous ne faites pas de votre vie un but, vous en ferez une prison».',
    author: 'Le ZamiSage'
}, {
    citation: '« Celui qui ne progresse pas chaque jour, recule chaque jour ».',
    author: 'Proverbe chinois.'
}, {
    citation: '« Il y a plus de plaisir à faire du bien qu’à en recevoir. C’est là que les hommes généreux font consister leur félicité ».',
    author: 'Gracian, l’Homme de la cour.'
}, {
    citation: '« Vous me demandez quel est le suprême bonheur ici-bas? C’est d’écouter la chanson d’une petite fille qui s’éloigne après vous avoir demandé son chemin » .',
    author: 'Li-Tai-Po'
}, {
    citation: '« Ne vous affligez pas de n’être connu de personne, mais travaillez à vous rendre digne d’être connu ».',
    author: 'Confucius'
}, {
    citation: '« La conscience est le meilleur livre du monde. C’est celui que l’on doit consulter le plus souvent ».',
    author: 'Pascal (Pensées)'
}, {
    citation: '« Ecoute avec ton esprit plutôt qu’avec tes oreilles».',
    author: 'Confucius'
}, {
    citation: '« Dans les grandes choses, les hommes se montrent comme il leur convient de se montrer; dans les petites, ils se montrent comme ils sont»',
    author: 'Chamfort. Maximes et pensées'
}, {
    citation: '«Quiconque arrose dans son cœur la plante de l’Amour n’a pas un seul jour de sa vie qui soit inutile ».',
    author: 'Khayyam. Quatrains'
}, {
    citation: '« Le plus grand conquérant est celui qui sait vaincre sans bataille».',
    author: 'Lao Tseu'
}, {
    citation: '« Un mauvais moment, un mauvais jour, une mauvaise semaine ne font pas de vous de mauvais parents ».',
    author: 'Pickleblums.com'
}, {
    citation: '« L’enfant n’a pas besoin d’un parent parfait mais de parents authentiques qui reconnaissent leurs erreurs et leurs envies de changer».',
    author: 'Arnaud Deroo'
}, {
    citation: '« En grandissant, le cerveau de l’enfant se construit en miroir de celui de des parents. Si les parents gagnent en équilibre émotionnel, les enfants en récoltent les fruits et évoluent eux aussi vers d’avantage d’équilibre. Cela signifie qu’intégrer et cultiver votre propre cerveau est le plus beau des cadeaux que vous puissiez faire à votre enfant».',
    author: 'Daniel Siegel'
}, {
    citation: '« Aucun enfant n’est rebelle ou ne s’oppose à ses parents par plaisir. S’il le fait, c’est que c’est la seule solution qu’il ait trouvée pour répondre à ses besoins ».',
    author: 'Isabelle Filliozat'
}, {
    citation: '«Quand on exige, commande, donne des ordres, on domine l’autre, on le soumet. L’autre n’a pas droit à la parole. On restreint sa liberté. Or la dignité de l’être humain, son désir le plus profond, est de pouvoir s’exprimer, de se sentir libre, de faire des choix ».',
    author: 'Catherine Gueguen'
}, {
    citation: '« Les ordres sont à ranger dans la même catégorie que les menaces, les accusations, les chantages, les cris, les punitions, les humiliations verbales, physiques. Ils n’éduquent pas et sont très nocifs pour le développement du cerveau ».',
    author: 'Catherine Gueguen'
}, {
    citation: '« Être bienveillant,  c\'est porter sur autrui un regard aimant,  compréhensif, sans jugement, en souhaitant  qu\'il se sente bien et en y veillant ».',
    author: 'Catherine Gueguen'
}, {
    citation: '« Ce n’est pas en regardant la lumière qu’on devient lumineux, mais en plongeant dans son obscurité. Mais ce travail est souvent désagréable, donc impopulaire».',
    author: 'Carl Jung'
}, {
    citation: '« Nul ne peut avoir de lien avec son prochain s’il ne l’a d’abord avec lui-même ».',
    author: 'Carl Jung'
}, {
    citation: '« Si vous ne faites pas face à votre ombre, elle vous viendra sous la forme de votre destin».',
    author: 'Carl Jung'
}, {
    citation: '« La rencontre de deux personnalités est comme le contact de deux substances chimiques: s’il se produit une réaction, les deux en sont transformés ».',
    author: 'Carl Jung'
}, {
    citation: '« Je ne suis pas ce qui m’est arrivé, je suis ce que je choisis de devenir ».',
    author: 'Carl Jung'
}, {
    citation: '«Tout ce qui nous irrite chez les autres peut nous mener à mieux nous comprendre nous-mêmes».',
    author: 'Carl Jung'
}, {
    citation: '« Connaître la face obscure de sa propre âme est la meilleure préparation qui soit pour savoir comment se comporter face aux parties obscures des autres âmes ».',
    author: 'Carl Jung'
}, {
    citation: '« Les enfants sont éduqués par ce que l’adulte est, non par ses bavardages. »',
    author: 'Carl Jung'
}, {
    citation: '« Trouvez ce dont une personne a le plus peur et vous saurez de quoi sera faite sa prochaine étape de croissance »',
    author: 'Carl Jung'
}, {
    citation: '« Votre vision devient claire lorsque vous pouvez regarder dans votre cœur. Celui qui regarde à l’extérieur de soi ne fait que rêver ; celui qui regarde en soi se réveille ».',
    author: 'Carl Jung'
}, {
    citation: '« La différence entre une personne dotée d’une volonté forte et une personne faible de ce côté-là ; c’est que le fort s’accroche tandis que le faible lâche prise. L’échec est une chose. C’es l’abandon qui n’est pas acceptable ».',
    author: 'Neil Strauss'
}, {
    citation: '« Chante comme si personne ne t\'écoutait. Aime comme si tu n\'avais jamais été blessé. Danse comme si personne ne te regardait et vis comme si c\'était le paradis sur terre ».',
    author: 'Mark Twain'
}, {
    citation: '« Notre plus grande arme contre le stress est notre capacité à choisir une pensée plutôt qu\'une autre ».',
    author: 'William James'
}, {
    citation: '« Si un problème a une solution il est inutile de s\'en inquiéter. S\'il n\'en a pas, s\'inquiéter n\'y changera rien ».',
    author: 'Proverbe Tibétain'
}, {
    citation: '« Si tu es humble, rien ne pourra te toucher, ni les éloges, ni les reproches, parce que tu sais qui tu es. Ne te laisse pas décourager par l\'échec si tu es persuadé d\'avoir fait de ton mieux ».',
    author: 'Mère Teresa'
}, {
    citation: '« L’homme le plus heureux est celui qui fait le bonheur d’un plus grand nombre d’autres ».',
    author: 'Diderot.'
}, {
    citation: '« Les générations futures… nous remercient au présent ».',
    author: 'A.Bennani'
}, {
    citation: '« La vérité n\'est pas le bout du chemin; elle est le chemin même ».',
    author: 'André Comte Sponville'
}, {
    citation: '« L\'homme a ce choix : laisser entrer la lumière ou garder les volets fermés ».',
    author: 'Henry Miller'
}, {
    citation: '« Plus grand est l\'obstacle, et plus grande la gloire de le surmonter ».',
    author: 'Molière'
}, {
    citation: '« Il n\'est jamais trop tard pour être ce que vous auriez pu être ».',
    author: 'George Eliot'
}, {
    citation: '« L\'homme qui ne tente rien ne se trompe qu\'une fois ».',
    author: 'Lao Tseu'
}, {
    citation: '« Tout ira mieux à la fin. Si ce n\'est pas mieux, c\'est que ce n\'est pas la fin ».',
    author: 'Ed Sheeran'
}, {
    citation: '« L\'impasse peut devenir le début d\'une voie ».',
    author: 'René Barjavel'
}, {
    citation: '« Donnez à ceux que vous aimez des ailes pour voler, des racines pour revenir et une raison de rester ».',
    author: 'Dalaï Lama'
}, {
    citation: '« Jouer, c\'est oser. Ensemble, en jouant, nous dépassons nos peurs ».',
    author: 'Pascal Deru'
}, {
    citation: '« Le secret du succès, s’il existe, c’est la faculté de se mettre à la place de l’autre et de considérer les choses de son point de vue autant que du notre ».',
    author: 'Dale Carnegie'
}, {
    citation: '« Faites que le rêve dévore votre vie afin que la vie ne dévore pas votre rêve ».',
    author: 'Antoine de Saint Exupéry'
}, {
    citation: '« Qu\'importent les trésors ! Plutôt qu\'argent entasser, mieux vaut amis posséder ».',
    author: 'Nikolaï Gogol'
}, {
    citation: '« La meilleure façon de prédire l\'avenir, c\'est de le créer ».',
    author: 'Peter Drucker'
}, {
    citation: '« Les bonnes résolutions ne gagnent pas à être différées ».',
    author: 'Jules Romains'
}, {
    citation: '« Le monde appartient aux optimistes, les pessimistes ne sont que des spectateurs ».',
    author: 'François Guizot'
}, {
    citation: '« L\'homme qui déplace une montagne commence par déplacer les petites pierres ».',
    author: 'Confucius'
}, {
    citation: '« Le monde est un miroir qui renvoie à chacun ses propres traits; si vous froncez le sourcil en le regardant, il vous jette un coup d\'œil renfrogné. Riez, au contraire, avec lui, et il se montrera bon compagnon ».', 
    author: ' William Thackeray '
}, { citation: ' « Tout groupe humain prend sa richesse dans la communication, l\'entraide et la solidarité visant à un but commun : l’épanouissement de chacun dans le respect des différences ».',
    author: 'Françoise Dolto'
}, {
    citation: '« Souvenez-vous que le bonheur dépend non pas de ce que vous êtes ou ce que vous possédez, mais uniquement de votre façon de penser ».',
    author: 'Dale Carnegie'
}, {
    citation: '« Si la nature désire un majestueux érable mature, elle attendra au moins 50 ans pour obtenir ce résultat. Cependant, si elle désire un navet, elle n\'attendra que 3 mois. La patience et la persévérance sont des ingrédients essentiels à la qualité de votre succès».',
    author: 'Paul Rousseau'
}, {
    citation: '« Chaque fois que vous voyez une entreprise qui réussit, dites-vous que c\'est parce qu\'un jour quelqu\'un a pris une décision courageuse ».',
    author: 'Peter Drucker'
}, {
    citation: '« Pense, croit, rêve et ose ».',
    author: 'Walt Disney'
}, {
    citation: '« Le contentement apporte le bonheur, même dans la pauvreté. Le mécontentement apporte la pauvreté, même dans la richesse ».',
    author: 'Confusius'
}, {
    citation: '« Si la vie te donne une centaine de raisons de pleurer, montres lui que tu as des milliers de raisons de sourire ».',
    author: 'Miley Cyrus'
}, {
    citation: '« Ne renoncez jamais à quelque chose que vous aimez ».',
    author: 'Ariana Grande'
}, {
    citation: '« Le fruit le plus agréable et le plus utile au monde est la reconnaissance ».',
    author: 'Ménandre'
}, {
    citation: '« Les espaces entre les doigts ont été crées pour laisser une autre personne les combler ».',
    author: '9Proverbes.com'
}, {
    citation: '« Si tu ne poursuis pas ce que tu désires, tu ne l’obtiendras jamais ».',
    author: 'Le Zamisage'
}, {
    citation: '« Mieux vaut boiter sur le bon chemin, que de courir sur la mauvais route ».',
    author: 'Le Zamisage'
}, {
    citation: '« Etre différent… c\'est normal ! »',
    author: 'A. Bennani'
}, {
    citation: '« On gagne une vie avec ce que l’on reçoit, mais on bâtit avec ce que l’on donne ».',
    author: 'Winston Churchill'
}, {
    citation: '«Ne laissez pas le comportement des autres détruire votre paix intérieure ».',
    author: 'Dalaï Lama'
}, {
    citation: '« Rien dans la vie n’a de signification en dehors de celle que VOUS lui donnez ».',
    author: 'Anthony Robbins'
}, {
    citation: '« L’amour c’est deux histoires différentes qui se rencontrent pour écrire ensemble un nouveau livre de la vie dans le respect des histoires vécues de chacun ».',
    author: 'Christine Gonzalez'
}, {
    citation: '« Ne t’inquiète pas de l’échec, inquiète-toi des chances que tu manques lorsque tu n’essaies même pas ».',
    author: 'Jack Canfield'
}, {
    citation: '« Rien ne vous emprisonne excepté vos pensées. Rien ne vous limite excepté vos peurs. Et rien ne vous contrôle excepté vos croyances ».',
    author: 'Marianne Williamson'
}, {
    citation: '« Crois aux petits miracles quotidiens et sois attentif aux coïncidences, car il y a en elles des messages cachés qui peuvent te guider sur le bon chemin ».',
    author: 'Sophie Beaumieu'
}, {
    citation: '« La peur fait échouer plus de gens que n’importe quel fléau au monde ».',
    author: 'Emerson'
}, {
    citation: '« La vigilance est le chemin du royaume immortel. La négligence, celui qui conduit à la mort ».',
    author: 'Bouddha'
}, {
    citation: '« Une once de pratique, vaut mieux qu’un grand discours ».',
    author: 'Gandy'
}, {
    citation: '« La moindre chose contient un peu d\'inconnu. Trouvons-le ».',
    author: 'Guy de Maupassant'
}, {
    citation: '« On ne choisit pas la manière dont on meurt, ni quand. On peut seulement choisir sa façon de vivre. Dès à présent ».',
    author: 'Joan Baez'
}, {
    citation: '« C\'est en essayant encore et encore que le singe apprend à bondir ».',
    author: 'Proverbe africain'
}, {
    citation: '« Personne ne se lasse d’être aidé. L’aide est un acte conforme à la nature. Ne te lasse jamais d’en recevoir ni d’en apporter ».',
    author: 'Marc-Aurèle'
}, {
    citation: '« Oublie ton passé qu\'il soit simple ou composé, participe a ton présent pour qu\'ensuite ton futur, soit plus que parfait » .',
    author: 'Le ZamiSage'
}, {
    citation: '« Quoi que tu fasses de ta vie, les gens te critiqueront toujours… Alors, fais ce que tu veux ».',
    author: 'Le ZamiSage'
}, {
    citation: '« Dans 20 ans, vous serez plus déçu par les choses que vous n’avez pas faites que par celles que vous avez faites. Alors sortez des sentiers battus. Mettez les voiles. Explorez. Rêvez. Découvrez ».',
    author: 'Mark Twain'
}, {
    citation: '« Tu seras aimé le jour où tu pourras montrer ta faiblesse, sans que l’autre s’en serve pour affirmer sa force ».',
    author: 'Cesare Pavese'
}, {
    citation: '« Tout ce qu’il faut est de mettre de l’ordre à l’intérieur de nous et l’extérieur prendra soin de lui-même ».',
    author: 'Eckhart Tolle'
}, {
    citation: '« Ne soyez pas poussé par vos problèmes, soyez dirigé par vos rêves ».',
    author: 'Ralph Waldo Emmerson'
}, {
    citation: '« La vie est une chanson : tu dois la chanter. La vie est un jeu : tu dois y jouer. La vie est un défi : tu dois le relever. La vie est un rêve : tu dois le réaliser. La vie est un sacrifice : tu dois l’offrir. La vie est amour : tu dois en profiter ».',
    author: 'Sai Baba'
}, {
    citation: '« La vie est courte. Arrêtez de perdre votre temps avec les mauvaises personnes qui ne vous permettent pas de vivre heureux. Si quelqu’un vous veut dans sa vie, il vous fera de la place, vous n’aurez pas besoin de lutter. Ne restez pas avec les gens qui vous ignorent. Appréciez non pas les gens qui vous accompagnent seulement dans les bons moments, mais ceux qui sont là aussi dans les pires moments ».',
    author: 'Le ZamiSage'
}, {
    citation: '« J’ai décidé d’être heureux parce que c’est bon pour la santé ».',
    author: 'Voltaire'
}, {
    citation: '« Vous pouvez dominer pratiquement toute crainte simplement en le décidant. Rappelez-vous que la crainte existe uniquement dans l’esprit ».',
    author: 'Dale Carnegie'
}, {
    citation: '« Il est important de savoir ménager l’égo des personnes qui nous entoure. Tous nous avons besoin de Reconnaissance, pas des critiques… ni même des conseils si nous ne l’avons pas demandé ».',
    author: 'Concept de Dale Carnegie'
}, {
    citation: '« Le secret du succès, s’il existe, c’est la faculté de se mettre à la place de l’autre et de considérer les choses de son point de vue autant que du notre ».',
    author: 'Henri Ford'
}, {
    citation: '« La tolérance ne devrait être qu\'un état passager ; elle doit (impérativement) conduire à la reconnaissance. Tolérer, c\'est insulter ».',
    author: 'Johann Wolfgang Von Goethe'
}, {
    citation: '« Il n\'y a guère au monde un plus bel excès que celui de la reconnaissance ».',
    author: 'Jean de La Bruyère'
}, {
    citation: '« L\'individu ne reçoit une dimension humaine que par la reconnaissance d\'autrui ».',
    author: 'Simone de Beauvoir'
}, {
    citation: '« Le blé et la reconnaissance ne poussent qu’en bonne terre ».',
    author: 'Proverbe Allemand'
}, {
    citation: '« La reconnaissance silencieuse ne sert à personne ».',
    author: 'Gladys Bronwyn Stern'
}, {
    citation: '« Rien de plus noble que la reconnaissance, rien de plus vil que l\'ingratitude ; autant un trait de reconnaissance touche l\'âme, autant un trait d\'ingratitude lui fait horreur ».',
    author: 'Hypolite de Livry'
}, {
    citation: '« Les grands esprits ont toujours rencontré une opposition farouche des esprits médiocres »',
    author: 'Albert Einstein'
}, {
    citation: '« L’Homme ordinaire est exigeant avec les autres. L’homme exceptionnel est exigeant avec lui-même ».',
    author: 'Marc Aurèle'
}, {
    citation: '« Le rire c’est comme les essuies glaces. Cela ne fait pas avancer mais cela permet de mieux voir ».',
    author: 'Gérard Jugnot'
}, {
    citation: '« J\'ai dans le cœur trois sentiments avec lesquels on ne s\'ennuie jamais : De la tristesse, de l\'amour et de la reconnaissance ».',
    author: 'Alexandre Dumas'
}, {
    citation: '« Un voyage de mille lieues commence toujours par un premier pas ».',
    author: 'Lao Tseu'
}, {
    citation: '« Un éléphant se mange entièrement. Mais un petit bout à la fois ».',
    author: 'Proverbe africain'
}, {
    citation: '« L’Homme qui veut battre un chien , trouvera toujours une raison, un bâton et quelqu’un pour lui donner raison. Quoi qu’il en soit reste déterminé mise si on te dit que tu perds la raison».',
    author: 'Marc Legros.'
}, {
    citation: '« On n\'est pas responsable de la tête qu\'on a, mais de la tête qu\'on fait ».',
    author: 'Cardinal Mercier'
}, {
    citation: '« Marcher devant le troupeau ne signifie pas qu\'on cesse d\'en faire partie ».',
    author: 'Maurice Duon'
}, {
    citation: '« L\'homme généreux invente même des raisons de donner ».',
    author: 'Publius Syrus'
}, {
    citation: '« S\'irriter d\'un reproche, c\'est reconnaître qu\'on l\'a mérité ».',
    author: 'Tacite'
}, {
    citation: '« Croyez en vous-même, en l’humanité, au succès de vos entreprises. Ne craignez rien ni personne ».',
    author: 'Baronne Staffe'
}, {
    citation: '« Il n’y a que les poissons morts qui vont dans le sens du courant ».',
    author: 'Proverbe chinois.'
}, {
    citation: '« Le temps adoucit tout ».',
    author: 'Voltaire'
}, {
    citation: '« Le succès, c’est d’aller d’échec en échec sans perdre son enthousiasme ».',
    author: 'Winston Churchill'
}, {
    citation: '« Le besoin le plus profond de l’être humain est d’avoir un sentiment d’importance ».',
    author: 'Dr John Dewey'
}, {
    citation: '« Ce qui est plus triste qu’une œuvre inachevée, c’est une œuvre jamais commencée ».',
    author: 'Christina Rossetti'
}, {
    citation: '« La reconnaissance fait durer le bienfait ».',
    author: 'Citation arabe'
}, {
    citation: '« Agis avec gentillesse, mais n\'attends pas de la reconnaissance ».',
    author: 'Confucius'
}, {
    citation: '« La reconnaissance du travail bien fait est une récompense souvent bien plus appréciée qu\'un salaire».',
    author: 'Le ZamiSage'
}, {
    citation: '« La vie c’est comme une bicyclette, il faut avancer pour ne pas perdre l’équilibre ».',
    author: 'Albert Einstein'
}, {
    citation: '« Dis ce que tu penses. Crie ce que tu ressens. Hurle ce qui te blesse. Aime les personnes que tu veux. Ignore les personnes qui te nuisent. Occupe ton temps pour réaliser tes rêves. Apprends de tes erreurs. Vis ta vie sans t’obstiner à plaire aux autres Reste…toi-même… C’est ta vie à TOI ».',
    author: 'Le ZamiSage'
}, {
    citation: '« Quand tu te plains, tu fais de toi une victime. Abandonne la situation, change la situation ou accepte là. Tout le reste n’est que de la folie ».',
    author: 'Eckhart Tolle'
}, {
    citation: '« En vérité, si une personne ne reçoit que des blâmes, des critiques et autres reproches, ou, pire, de l’indifférence, ne se sentent pas reconnue, tôt ou tard, elle partira pour chercher d’autres contrées plus accueillantes. D’où l’importance des « marques de Reconnaissance » délivrées tout le temps, car il ne faut pas attendre la bonne occasion, la bonne date, pour dire à nos amis combien nous les apprécions ».',
    author: 'Marc Legros'
}, {
    citation: '« Rien n’est plus facile que de critiquer. La difficulté réside à convertir ce qui est négatif en positif ».',
    author: 'Will Rogers'
}, {
    citation: '« Si un homme espère d’une femme qu’elle soit un ange dans sa vie, il doit en premier lieu créer un ciel pour elle. Les anges ne vivent pas en enfer ! Ceci est valable aussi du côté des hommes. Ainsi, si une femme espère d’un homme qu’il soit un ange dans sa vie, elle doit en premier lieu créer un ciel pour lui. Les anges ne vivent pas en enfer ! Alors créons, tous, le paradis sur Terre. »',
    author: 'Le ZamiSage'
}, {
    citation: '« J’aime les esprits indisciplinés. Ceux qui pensent à contre sens, mais parlent avec justesse de leurs idées folles ».',
    author: 'Frédérique Deghell'
}, {
    citation: '« Parce que chacun de nous est unique, il ne peut y avoir ni comparaison, ni concurrence ».',
    author: 'Le ZamiSage'
}, {
    citation: '« Aller doucement, n’empêche pas d’avancer ».',
    author: 'Le ZamiSage'
}, {
    citation: '« Si vous détestez une personne, vous détestez quelque chose en elle qui fait partie de vous-même. Ce qui ne fait pas partie de nous-mêmes ne nous dérange pas ».',
    author: 'Le ZamiSage'
}, {
    citation: '« Le bonheur ne nous est pas donné, ni le malheur imposé. Nous sommes à chaque instant à la croisée des chemins et il nous appartient de choisir la direction à prendre ».',
    author: 'Le ZamiSage'
}, {
    citation: '« La vie est comme un écho ; ce que tu envoies, te revient, ce que tu sèmes, tu le récoltes, ce que tu donnes, tu l’obtiens et ce que tu vois dans les autres, existe en toi ».',
    author: 'Zig Ziglar'
}, {
    citation: '« Ne discutez jamais, vous ne convaincrez personne. Les opinions sont comme des clous ; plus on tape dessus, plus on les enfonce ».',
    author: 'Alexandre Dumas, fils'
}, {
    citation: '« Au lieu de raturer sur un passé que l’on ne sait pas abolir, essayez de construire un présent dont vous serez ensuite fier ».',
    author: 'André Maurois'
}, {
    citation: '« Une route très fréquentée n’est pas forcément le chemin à suivre ».',
    author: 'Un ZamiSage'
}, {
    citation: '« Ne laisse personne juger ta vie et tes choix, car personne n’a traversé tes douleurs, tes joies, et encore moins tes peines ».',
    author: 'Le ZamiSage'
}, {
    citation: '« Ouvrir les yeux est un antidote au désespoir ».',
    author: 'Sylvain Tesson'
}, {
    citation: '« Aussi longtemps que les lions n\'auront pas leur historien, les récits de chasse tourneront toujours à la gloire du chasseur».',
    author: 'Proverbe africain'
}, {
    citation: '« Tout le bonheur du monde est dans l\'inattendu ».',
    author: 'Jean d\'Ormesson'
}, {
    citation: '« On n\'essaierait jamais rien s\'il fallait d\'abord répondre à toutes les objections ».',
    author: 'Samuel Johnson'
}, {
    citation: '« Que sait du désert celui qui ne regarde qu’un grain de sable».',
    author: 'Eric Orsenna'
}, {
    citation: '« A vaincre sans péril, on évite des ennuis ! ».',
    author: 'René Goscinny'
}, {
    citation: '« L\'impossible est le seul adversaire digne de l\'homme ».',
    author: 'Andrée Chedid'
}, {
    citation: '« Nous avons besoin d\'hommes qui sachent rêver à des choses inédites ».',
    author: 'John Fitzgerald Kennedy'
}, {
    citation: '« Soyez réalistes : demandez l\'impossible ».',
    author: 'Ernesto "Che" Guevara'
}, {
    citation: '« Les soucis d\'aujourd\'hui sont les plaisanteries de demain. Rions-en donc tout de suite ».',
    author: 'Henri Béraud'
}, {
    citation: '« Le plus grand prix qu\'on puisse payer pour quoi que ce soit, c\'est de le demander ».',
    author: 'Marcel Achard'
}, {
    citation: '« Toutes les bonnes choses qui existent sont les fruits de l\'originalité ».',
    author: 'John Stuart Mill'
}, {
    citation: '« Si vous fermez la porte à toutes les erreurs, la vérité restera dehors ».',
    author: 'Rabindranàth Tagore'
}, {
    citation: '« L\'illusion seule est aisée. La vérité est toujours difficile ».',
    author: 'Rabindranàth Tagore'
}, {
    citation: '« Je ne veux pas prier d\'être protégé des dangers, mais de pouvoir les affronter ».',
    author: 'Rabindranàth Tagore'
}, {
    citation: '« Qui vit en paix avec lui-même vit en paix avec l’univers ».',
    author: 'Marc Aurèle'
}, {
    citation: '« 1) Lâche prise avec la comparaison, la compétition, les jugements, les regrets, les soucis, les autocritiques, la culpabilité, la colère. 2) Ris à gorge déployée au moins une fois par jour (surtout si c\'est à propos de ton incapacité de lâcher prise avec tout ou partie de cette liste) 3) Ressource-toi dans la nature ».',
    author: 'Le ZamiSage'
}, {
    citation: '« L\'avantage d\'être intelligent, c\'est qu\'on peut toujours faire l\'imbécile, alors que l\'inverse est totalement impossible ».',
    author: 'Woody Allen'
}, {
    citation: '« L’amitié n’exige rien en échange, que de l’entretien ».',
    author: 'Georges Brassens'
}, {
    citation: '« Ce n’est pas dans l’objet que réside le sens des choses, mais dans la démarche ».',
    author: 'Antoine de SaintExupéry'
}, {
    citation: '« Tu es responsable de ce que tu as apprivoisé ».',
    author: 'Antoine de Saint Exuépry'
}, {
    citation: '« Si tu continues ce que tu as toujours fait, tu n’obtiendras jamais ni plus ni moins que la même chose. Ose le changement ! ».',
    author: 'Le ZamiSage'
}, {
    citation: '« Mieux vaut vivre un jour comme un lion que cent ans comme un mouton ».',
    author: 'Proverbe Italien'
}, {
    citation: '« Dieu nous a donné deux extrémités: l’une pour s’assoir, l’autre pour réfléchir. La vie dépend de celle qu’on utilise: face on gagne, pile on perd ».',
    author: 'Paul-Emile Victor'
}, {
    citation: '« Les larmes qu’on ne verse pas empoisonnent l’esprit ».',
    author: 'Raymond E. Feist'
}, {
    citation: '« L\'humilité consiste aussi à reconnaître que n\'importe quelle créature dans l\'Univers est susceptible de nous enseigner ce que nous ignorons ».',
    author: 'Runi'
}, {
    citation: '« L\'excentricité et la force de caractère marchent toujours de pair ».',
    author: 'John Stuart Mill'
}, {
    citation: '« Seul celui qui est heureux peut répandre du bonheur autour de lui ».',
    author: 'Paulo Coelho'
}, {
    citation: '« N’essayez pas de devenir un homme qui a du succès. Essayez de devenir un homme qui a de la valeur ».',
    author: 'Albert Einstein'
}, {
    citation: '« Le moyen d’aimer une chose est de se dire qu’on pourrait le perdre ».',
    author: 'Gilbert Keith Chersterton'
}, {
    citation: '« Le vieux sage a dit : "L\'homme jeune marche plus vite que l’ancien, mais lui connaît la route ».',
    author: 'Proverbe chinois'
}, {
    citation: '« L\'optimiste est un aimant à bonheur. Si tu restes positif, les bonnes choses viendront à toi ».',
    author: 'Joyce Meyer'
}, {
    citation: '« Quand on veut, on peut!».',
    author: 'Marcel Legros'
}, {
    citation: '« Il faut toujours viser la lune? Car même en cas d\'échec, on atterrit dans les étoiles ».',
    author: 'Oscar Wilde'
}, {
    citation: '« La chose la plus importante concernant les objectifs, c\'est d\'en avoir un ».',
    author: 'Geoffrey F. Albert'
}, {
    citation: '« Si tu as une pomme, que j\'ai une pomme et que l\'on échange nos pommes, nous aurons chacun une pomme. Mais si tu as une idée, que j\'ai une idée et que l\'on échange nos idées, nous aurons chacun deux idées ».',
    author: 'Georges Bernard Shaw'
}, {
    citation: '« La vraie générosité envers l’avenir consiste à tout donner au présent ».',
    author: 'Albert Camus'
}, {
    citation: '« Quand une porte se ferme, une autre s\'ouvre ».',
    author: 'Miguel de Cervantès'
}, {
    citation: '« Une seule chose peut nous aider. Il faut trouver le puits qui est en nous. C’est le seul moyen de ne plus jamais avoir soif».',
    author: 'Prem Rawat'
}, {
    citation: '« Il y aura toujours une raison pour laquelle une personne entre dans ta vie; soit cette personne changera ta vie ou tu es celui qui changera la sienne ».',
    author: 'Le ZamiSage'
}, {
    citation: '« On ne peut pas rattraper le temps perdu mais on peut arrêter de perdre son temps ».',
    author: 'Jennifer Lawrence'
}, {
    citation: '« Le plus souvent l’apparence déçoit, il ne faut pas juger sur ce qu’on voit ».',
    author: 'Molière'
}, {
    citation: '« Lorsque la dernière goutte d’eau sera polluée, le dernier animal chassé et le dernier arbre coupé, l’homme blanc comprendra que l’argent ne se mange pas ».',
    author: 'Sitting Bull'
}, {
    citation: '« La terre n’est pas un don de nos parents, ce sont nos enfants qui nous la prêtent ».',
    author: 'Proverbe indien'
}, {
    citation: '« Adopte le rythme de la nature, son secret est la patience ».',
    author: 'Ralph Waldo Emerson'
}, {
    citation: '« Approfondir nos liens affectifs avec la nature est tout aussi essentiel à notre bien-être qu’entretenir des liens étroits avec notre famille et nos amis ».',
    author: 'Sarah Ban Breathnach'
}, {
    citation: '« Si nous prenons la nature pour guide, nous ne nous égarerons pas ».',
    author: 'Cicéron'
}, {
    citation: '« Avance souplement au rythme de la nature, en accord avec elle, car ses rythmes sont parfaits. Il y a une place pour chaque chose et chaque chose y a sa place. Tout ce qui est selon la nature est digne d’estime ».',
    author: 'Cicéron'
}, {
    citation: '« Va prendre tes leçons dans la nature ».',
    author: 'Léonard de Vinci'
}, {
    citation: '« C’est beau, c’est sain, c’est pur; ça aide à vivre que de marcher tout doucement dans la nature et d’admirer les fleurs des champs, les oiseaux, les nuages, le ciel et la vie! ».',
    author: 'Le ZamiSage'
}, {
    citation: '« C’est une triste chose de songer que la nature parle et que le genre humain ne l’écoute pas ».',
    author: 'Victor Hugo'
}, {
    citation: '« Le courage ne rugit pas toujours. Parfois, le courage est cette petite voix calme à la fin de la journée qui dit « je vais essayer à nouveau demain ».',
    author: 'Mary Anne Radmacher'
}, {
    citation: '« Observe tous les jours quelque chose qui: -court sur le sol, -vole, -nage, -sort de terre. Et rend grâce à Dieu pour tant de merveilles».',
    author: 'Marcel Legros'
}, {
    citation: '« Tant qu’un homme n’a pas découvert quelque chose pour lequel il serait prêt à mourir, il n’est pas à même de vivre ».',
    author: 'Martin Luther King'
}, {
    citation: '« Chanter c’est comme honorer l’oxygène ».',
    author: 'Björk'
}, {
    citation: '« La vérité est comme le soleil. Elle fait tout voir et ne se laisse pas regarder ».',
    author: 'Victor Hugo'
}, {
    citation: '« Je sais pourquoi tant de gens aiment couper du bois. C’est une activité où l’on voit tout de suite le résultat ».',
    author: 'Albert Einstein'
}, {
    citation: '« Suivez l’enseignement de l’eau dévalant la rivière face à un obstacle; contournez-le, enveloppez-le; purifiez-le… puis poursuivez votre chemin! ».',
    author: 'Paul Rousseau'
}, {
    citation: '« Ne fais pas à autrui ce que tu n’aimerais pas qu’on te fasse ».',
    author: 'Règle d’Or de beaucoup de religions dont le Christianisme.'
}, {
    citation: '« L’art de la réussite consiste à savoir s’entourer des meilleurs ».',
    author: 'John Fitzgerald Kennedy'
}, {
    citation: '« Si vous ne pouvez expliquer un concept à un enfant de six ans, c’est que vous ne le comprenez pas complètement ».',
    author: 'Albert Einstein'
}, {
    citation: '« Etre homme, c’est précisément être responsable. C’est sentir, en posant sa pierre, que l’on contribue à bâtir le monde ».',
    author: 'Antoine de Saint-Exupéry'
}, {
    citation: '« Tant que les mots restent dans notre bouche, ils sont à soi; sitôt prononcés, ils sont à tout le monde ».',
    author: 'Proverbe chinois'
}, {
    citation: '« Essayez de laisser ce monde un peu meilleur qu\'il ne l\'était quand vous y êtes venus ».',
    author: 'Lord Baden POWELL'
}, {
    citation: '« Le bonheur ne vient pas à ceux qui l’attendent assis ».',
    author: 'Lord Baden POWELL'
}, {
    citation: '« La meilleure façon d’atteindre le bonheur est de le donner aux autres ».',
    author: 'Lord Baden POWELL'
}, {
    citation: '« Rester immobile ne sert à rien. Il faut choisir entre progresser ou régresser. Allons donc de l’avant et le sourire aux lèvres ».',
    author: 'Lord Baden POWELL'
}, {
    citation: '« La question n’est pas « qu’est-ce que je peux avoir? » mais « qu’estce que je peux donner à la vie ».',
    author: 'Lord Baden POWELL'
}, {
    citation: '« Les connaissances sans le caractère ne sont que la croûte sans le pâté».',
    author: 'Lord Baden POWELL'
}, {
    citation: '« L’ambition de faire le bien est la seule qui compte».',
    author: 'Lord Baden POWELL'
}, {
    citation: '« L’optimise est une forme de courage qui donne confiance aux autres et mène au succès ».',
    author: 'Lord Baden POWELL'
}, {
    citation: '« Les connaissances qu’on a cherchées restent, celles qu’on n’a pas cherchées se perdent ».',
    author: 'Lord Baden POWELL'
}, {
    citation: '« Faites votre devoir d’abord, vos droits vous seront reconnus ensuite ».',
    author: 'Lord Baden POWELL'
}, {
    citation: '« Vous devez toujours essayer de compter sur vous-mêmes et non pas sur ce que les autres peuvent faire pour vous ».',
    author: 'Lord Baden POWELL'
}, {
    citation: '« Une difficulté n’en es plus une, à partir du moment où vous en souriez, où vous l’affrontez ».',
    author: 'Lord Baden POWELL'
}, {
    citation: '« Que ton ambition soit de voir, non pas ce que tu pourras tirer du travail, mais ce que tu pourras y mettre de toi ».',
    author: 'Lord Baden POWELL'
}, {
    citation: '« Une mauvaise habitude est comme une mauvaise dent. Arrache-là ».',
    author: 'Lord Baden POWELL'
}, {
    citation: '« La vie est trop courte pour qu’on se dispute ».',
    author: 'Lord Baden POWELL'
}, {
    citation: '« Contentez-vous de ce que vous avez et faites-en le meilleur usage possible ».',
    author: 'Lord Baden POWELL'
}, {
    citation: '« Les idées sont comme des fleurs de lotus, elles ne dorment que pour mieux pousser ».',
    author: 'Fatou Diome'
}, {
    citation: '« L’adversité contient toujours le ferment d’une nouvelle chance. Parfois, les événements néfastes sont en réalité de bonnes occasions déguisées ».',
    author: 'Christophe Godefroid'
}, {
    citation: '« On se souvient du baiser promis, on oublie les baisers reçus ».',
    author: 'Proverbe Scandinave'
}, {
    citation: '« Les gens ne connaissent pas leur bonheur, mais celui des autres ne leur échappe pas ».',
    author: 'Pierre Daninos'
}, {
    citation: '« Tout ce qui te répugne, ne le fais pas non plus aux autres ».',
    author: 'Shayast-na-Shayast'
}, {
    citation: '« Il y a de l’or dans l’erreur ».',
    author: 'Mathieu Chedid'
}, {
    citation: '« De temps en temps, il faut savoir se montrer courageux et sauter du haut de la falaise».',
    author: 'Björk'
}, {
    citation: '« Les corps sont étranges. Ce qui est à l’extérieur ne correspond pas toujours à ce qu’il y a à l’intérieur ».',
    author: 'Kitty Fitzgerals'
}, {
    citation: '« A l’intérieur comme à l’ extérieur, où que vous soyez vous y êtes ».',
    author: 'Jon Kabet Zinn'
}, {
    citation: '« Ne juge pas chaque jour à la récolte que tu fais mais aux graines que tu sèmes ».',
    author: 'Robert Louis Stevenson'
}, {
    citation: '« Ce qui importe, ce n’est pas d’arriver, mais d’aller vers ».',
    author: 'Antoine de SaintExupéry'
}, {
    citation: '« Demandez et l’on vous donnera; cherchez et vous trouverez; frappez et l’on vous ouvrira ».',
    author: 'Mathieu 7,7'
}, {
    citation: '« Le malheur peut être un pas vers le bonheur ».',
    author: 'Proverbe japonais'
}, {
    citation: '« Une loi ne pourra jamais obliger un homme à m’aimer mais il est important qu’elle lui interdise de me lyncher ».',
    author: 'Martin Luther King'
}, {
    citation: '« Il n’existe que deux choses infinies, l’univers et la bêtise humaine… mais pour l’univers, je n’ai pas de certitude absolue ».',
    author: 'Albert Einstein'
}, {
    citation: '« Tout le monde peut être important car tout le monde peut servir à quelque chose».',
    author: 'Martin Luther King'
}, {
    citation: '« Celui qui se perd dans sa passion perd moins que celui qui perd sa passion».',
    author: 'Alexandre Jardin'
}, {
    citation: '« Ce ne sont pas les richesses qui font le bonheur, mais l’usage qu’on en fait».',
    author: 'Miguel de Cervantès'
}, {
    citation: '« Avoir la foi, c’est croire en ce que l’on ne voit pas encore; cette foi est récompensée lorsqu’on voit enfin ce à quoi on croyait».',
    author: 'Saint Augustin'
}, {
    citation: '« Il n’y a que deux façons de vivre sa vie: l’une en faisant comme si rien n’état un miracle, l’autre en faisant comme si tout était un miracle».',
    author: 'Albert Einstein'
}, {
    citation: '« Il n’y a qu’une chose qui puisse rendre un rêve impossible, c’est la peur d’échouer».',
    author: 'Paulo Coelho'
}, {
    citation: '« Celui qui se plaint que le monde lui a manqué , c’est qu’il a manqué au monde ».',
    author: 'Antoine de SaintExupéry'
}, {
    citation: '« Traitez les gens comme s’ils étaient ce qu’ils doivent être et vous les aiderez à devenir ce qu’ils sont capables d’être ».',
    author: 'Goethe'
}, {
    citation: '« Le monde ne mourra jamais par manque de merveilles mais uniquement par manque d’émerveillement ».',
    author: 'Gilbert Keith Chesteron'
}, {
    citation: '« La vie est pleine de revers. Le succès est déterminé par la façon dont nous les traitons ».',
    author: 'Tal Ben-Shahar'
}, {
    citation: '« Il est temps de vivre la vie que tu t’es imaginée».',
    author: 'Henri James'
}, {
    citation: '« L’amour se passe de cadeaux mais pas de présence».',
    author: 'Félix Leclerc'
}, {
    citation: '« Que la force me soit donnée de supporter ce qui ne peut être changé et le courage de changer ce qui peut l’être mais aussi la sagesse de distinguer l’un de l’autre».',
    author: 'Marc-Aurèle'
}, {
    citation: '« La connaissance s’acquiert par l’expérience, tout le reste n’est que de l’information».',
    author: 'Albert Einstein'
}, {
    citation: '« Voila la morale parfaite: vivre chaque jour comme si c’était le dernier, ne pas s’agiter, ne pas sommeiller, ne pas faire semblant».',
    author: 'Marc-Aurèle'
}, {
    citation: '« Soyez bref, car les discours qui n’en finissent pas ne plaisent pas».',
    author: 'Miguel de Cervantès'
}, {
    citation: '« Au dessus des nuages, il y a toujours le soleil».',
    author: 'Pierre Dudan'
}, {
    citation: '« Faire rire, c’est faire oublier. Quel bienfaiteur sur Terre qu’un distributeur d’oubli!».',
    author: 'Victor Hugo'
}, {
    citation: '« Aimer, ce n’est pas se regarder l’un l’autre, c’est regarder ensemble dans la même direction!».',
    author: 'Antoine de SaintExupéry'
}, {
    citation: '« Celui qui pose une question risque cinq minutes d’avoir l’air bête. Celui qui ne pose pas de question restera bête toute sa vie!».',
    author: 'Proverbe chinois'
}, {
    citation: '« Au royaume de l’espoir, il n’y a pas d’hiver!».',
    author: 'Proverbe russe'
}, {
    citation: '« La blessure cicatrisée, on oublie la douleur ».',
    author: 'Proverbe chinois'
}, {
    citation: '« Deux mains qui se cherchent, c’est assez pour le toit de demain ».',
    author: 'André Breton'
}, {
    citation: '« L’oreille est le chemin du cœur ».',
    author: 'Voltaire'
}, {
    citation: '« Ce n’est pas le lit dans lequel tu es né qui compte, c’est le cœur qui bat dans ta poitrine ».',
    author: 'Jean Van Hamme'
}, {
    citation: '« On peut aussi bâtir quelque chose de beau avec les pierres qui entravent le chemin».',
    author: 'Johann Wolgang Von Goethe'
}, {
    citation: '« Se réunir est un début; rester ensemble est un progrès; travailler ensemble est la réussite».',
    author: 'Henry Ford'
}, {
    citation: '« Le désert cache toujours un puits quelque part».',
    author: 'Antoine de Saint-Exupéry'
}, {
    citation: '« Nul ne peut atteindre l’aube sans passer par le chemin de la nuit».',
    author: 'Khalil Gibran'
}, {
    citation: '« Le monde est dangereux à vivre! Non pas tant à cause de ceux qui font le mal, mais à cause de ceux qui regardent et laissent faire».',
    author: 'Albert Einstein'
}, {
    citation: '« Fais de ta vie un rêve, d’un rêve, une réalité».',
    author: 'Antoine de Saint-Exupéry'
}, {
    citation: '« Sans imagination il ne peut y avoir création».',
    author: 'Albert Jacquard'
}, {
    citation: '« Inventer c’est penser à côté».',
    author: 'Albert Einstein'
}, {
    citation: '« Les gens ont toujours peur de la nouveauté. Pour faire du neuf, il faut se donner le droit à l’erreur».',
    author: 'Björk'
}, {
    citation: '« La douceur est invincible».',
    author: 'Marc-Aurèle'
}, {
    citation: '« La précipitation vient du diable. Dieu travaille lentement».',
    author: 'Proverbe persan'
}, {
    citation: '« Marc-Aurèle disait: - «Dès l’aurore, dis-toi d’avance: je vais rencontrer un indiscret, un ingrat, un insolent, un fourbe, un envieux, un égoïste». Avec le jeu du ScraZami, je complète en disant: -« Tu rencontreras des ScraCleenS, ScraMèrePouleS, ScraConformS, ScraMoiJeS, ScraDroitRigideS, ScraleurS, ScraNoirS, ScraDinS, ScramboS, ScraTGVStresS, ScraGekS, ScraDouilletS, ScraJugeS, ScraDeS, ScraCoolS, ScraMagouilleS, ScraBisnessFricS, SraZizaHaÏS. Méfie-toi des trois derniers, ainsi que des Scras qui se trouvent dans ta propre tête. … Entoure-toi de ZamiSageS, de ZamiZenS et surtout de ZamiCoeurS… Ceux dans ta tête s’écoutent dans le calme… ».',
    author: 'Marc Legros'
}, {
    citation: '« Ce n\'est pas en secouant une ruche qu\'on récolte du miel » .',
    author: 'Dale Carnegie.'
}, {
    citation: '« Tâche de voir ce que personne ne voit. Vois ce que le autres choisissent de ne pas voir, par peur, par conformisme et paresse mentale. Change ton regard sur le monde, découvre-le» .',
    author: 'Arthur Mendelson et le Docteur Patch Adams'
}, {
    citation: '« Regarder l’autre, l’écouter, lui sourire, s’intéresser à lui, d’après-moi, c’est le commencement de l’être humain».',
    author: 'Sœur Emmanuel'
}, {
    citation: '« Qu\'est-ce qui ne va pas avec la mort ? De quoi avons-nous si mortellement peur ? Pourquoi ne pouvons-nous pas traiter la mort avec un peu d\'humanité, de dignité et de décence, et même, Dieu nous en garde, avec un peu d\'humour ? La mort n\'est pas l\'ennemie, messieurs. Si nous devons combattre une maladie, battons-nous contre la plus terrible de toutes les maladies, l\'indifférence ».',
    author: 'Docteur Patch Adams'
}, {
    citation: '« Vous êtes l’auteur de votre propre bonheur et c’est à vous de décider, le matin au réveil, que votre journée sera heureuse».',
    author: 'Docteur Patch Adams'
}, {
    citation: '« Une grande partie de la souffrance est inutile. On se l’inflige à soi-même. Une grande partie de la souffrance humaine est associée à une forme ou à une autre de résistance, de non-acceptation de ce qui est ».',
    author: 'Eckhart Tolle'
}, {
    citation: '« C\'est au pays des merveilles qu\'on habite… quand de merveilleux sentiments nous habitent ! »',
    author: 'A. Bennani'
}, {
    citation: '« Le problème c’est pas le problème. Le problème c’est ton attitude face au problème! »',
    author: 'Jack Sparrow'
}, {
    citation: '« Le plus grand échec est de ne pas avoir le courage d’oser »',
    author: 'Abbé Pierre'
}, {
    citation: '« Celui qui aime à demander conseil, grandira »',
    author: 'Proverbe'
}, {
    citation: '« Si nous voulons apporter la paix au monde extérieur, il faut que la paix règne dans notre monde intérieur. La paix n’est pas une décision intellectuelle. C’est une expérience» .',
    author: 'Amma'
}, {
    citation: '« Le désespoir est la matière première d’un changement radical. Seuls ceux capables de laisser derrière eux tout ce à quoi ils ont toujours cru peuvent espérer y échapper» .',
    author: 'William S. Burroughs'
}, {
    citation: '« Prenez des risques! La vie est faite d’une multitude de risques. Celui qui réussit est souvent celui qui ose. Tracezvous une ligne de conduite et suivez la. Agissez constamment, travaillez dur, vous oublierez toute appréhension ».',
    author: 'Dale Carnegie'
}, {
    citation: '« Le naturel est la chose la plus facile à acquérir, tout simplement en vous oubliant vous-même, en oubliant de chercher à produire une impression».',
    author: 'Dale Carnegie'
}, {
    citation: '« Le succès que j’ai eu dans la vie, je le dois à mon enthousiasme, bien plus qu’à une intelligence supérieure. Je suis né enthousiaste. Durant toute ma vie adulte, j’ai été fasciné par l’enthousiasme comme un facteur déterminant du succès».',
    author: 'Dale Carnegie'
}, {
    citation: '« Ne soyons pas si occupé ou si pressé de vivre que nous ne puissions écouter la musique de la prairie ou la symphonie de la forêt. Bien plus importante que la richesse est la capacité d’apprécier les choses simples».',
    author: 'Dale Carnegie'
}, {
    citation: '« Ce n’est pas ce que vous êtes, ce que vous avez, l’endroit où vous êtes, ni ce que vous faites, qui vous rend heureux ou malheureux. C’est de que vous pensez. Deux personnes peuvent être au même endroit, faire la même chose, disposer de la même fortune et de la même réputation. L’une peut être heureuse et l’autre malheureuse. Pourquoi? A cause d’attitudes mentales différentes».',
    author: 'Dale Carnegie'
}, {
    citation: '« L’individu qui ne s’intéresse pas à ses semblables est celui qui rencontre le plus de difficultés dans l’existence et nuit le plus aux autres».',
    author: 'Alfred Adler (philosophe)'
}, {
    citation: '« Le principe le plus profond de la Nature humaine est la soif d’être apprécié. C’est plus fort que le souhait ou le désir».',
    author: 'William James'
}, {
    citation: '« Le mobile le plus puissant est le désir d’être important ».',
    author: 'John Dewey'
}, {
    citation: '« Aimez votre vie telle qu’elle est. Même dans la misère, on peut vivre des heures plaisantes, passionnantes, glorieuses. Le soleil couchant se reflète autant sur les fenêtres de la masure que dans celle du manoir ».',
    author: 'Henri David Thoreau'
}, {
    citation: '« Une fois que nous avons accepté le pire, nous n’avons plus rien à perdre, ce qui signifie automatiquement que nous avons tout à gagner».',
    author: 'Willis Carnier'
}, {
    citation: '« Si vous n’avez pas réussi quelque chose que vous vouliez vraiment réaliser, n’acceptez pas la défaite. Tentez quelque chose, vous avez plus d’une corde à votre arc».',
    author: 'Dale Carnegie'
}, {
    citation: '« Résignez-vous à accepter la situation telle qu’elle est. Résignez-vous, car accepter ce qui est passé est la première étape pour surmonter les conséquences n’importe quel malheur ».',
    author: 'William James'
}, {
    citation: '« On a posé un jour cette question à George Bernard Shaw: -« Comment avez-vous appris à parler en public? » Il a alors répondu: « De la même façon que j’ai appris à patiner: je me suis obstinément rendu ridicule jusqu’à ce que je sache ».',
    author: 'Dale Carnegie'
}, {
    citation: '« Lorsqu’une des portes du bonheur se referme, une autre s’ouvre; mais souvent nous regardons si longtemps la porte fermée que nous ne voyons pas celle qui nous a été ouverte ».',
    author: 'Helen Adams Keller'
}, {
    citation: '« Dans presque tous les domaines, votre passion pour le sujet vous sauvera. Si vous souhaitez fortement obtenir une chose, vous l’obtiendrez. Si vous voulez être bon, vous serez bon. Si vous voulez être riche, vous serez riche. Si vous voulez être cultivé, vous serez cultivé. Seulement vous devez réellement le souhaiter et le souhaiter exclusivement, sans désirer avec la même passion cent autres choses qui sont incompatibles».',
    author: 'William James'
}, {
    citation: '« Le monde s’écarte pour laisser passer celui qui sait où il va».',
    author: 'David Starr Jordan'
}, {
    citation: '« Quand on exige, commande, donne des ordres, on domine l’autre, on le soumet. L’autre n’a pas droit à la parole. On restreint sa liberté. Or la dignité de l’être humain, son désir le plus profond, est de pouvoir s’exprimer, de se sentir libre, de faire des choix. L’idéal est d’être avec l’enfant comme nous souhaiterions qu’il soit avec nous. Nous n’aimons pas recevoir des ordres, lui non plus. Si nous lui donnons des ordres, soit il va se soumettre, s’inhiber et une partie de lui va s’éteindre, soit il va nous imiter. Il donnera des ordres à ses parents, à ses frères, à ses sœurs, à ses copains de classe. »',
    author: 'Catherine Gueguen'
}, {
    citation: '« Les actes parlent plus fort que les mots. Un sourire dit : « Je vous apprécie, vous me rendez heureux. Je suis content de vous voir ». C’est pourquoi les chiens sont si souvent appréciés. Ils sont si contents d’une rencontre qu’ils sortent presque de leur peau. Ils rendent donc naturellement heureux de les voir. Un sourire qui n’est pas sincère? Non. Cela ne trompe personne. Nous savons qu’il est mécanique et nous sommes sur notre défensive. Je parle du vrai sourire, un sourire qui réchauffe, un sourire qui vient de l’intérieur: ce genre de sourire possède une grande valeur».',
    author: 'Dale Carnegie'
}, {
    citation: '« Vous avez maintenant le pouvoir d’augmenter facilement la somme totale du bonheur de ce monde. Comment? En faisant en quelques mots un compliment sincère à quelqu’un qui se sent seul ou découragé. Vous oublierez peut-être demain les mots aimables que vous aurez dits, mais celui qui les entends pourrait bien les chérir toute sa vie».',
    author: 'Dale Carnegie'
}, {
    citation: '« Une des raisons principales du succès est la faculté de maintenir un intérêt quotidien dans son travail, de faire constamment preuve d’enthousiasme, de considérer chaque jour comme important».',
    author: 'William Lyon Phelps'
}, {
    citation: '« Faites chaque jour un peu plus que vous ne vous sentez capable».',
    author: 'Lowell Thomas'
}, {
    citation: '« Si tu veux être aimé, aime».',
    author: 'Sénèque.'
}, {
    citation: '« Aujourd’hui , c’est la vie -la seule vie dont vous soyez sûr. Tirez le maximum de ce jour. Intéressez-vous à quelque chose. Empressez-vous. Trouvez un centre d’intérêt. Laissez-vous traverser par le souffle de l’enthousiasme. Vivez cette journée à fond ».',
    author: 'Dale Carnegie'
}, {
    citation: '« On est en train de courir le plus vite possible dans la pire des directions. La direction de la compétition, la direction de la destruction les uns envers les autres, c’est une folie totale. Ce qui me semble par exemple monstrueux, c’est de penser que l’on a pris comme moteur de notre société occidentale la compétition, il faut être meilleur que l’autre pour passer devant l’autre… Mais songeons à ça : pour devenir moi, j’ai besoin du regard de l’autre, j’ai besoin de tisser des liens avec lui, dès que je suis en compétition avec lui je ne tisse plus de liens et par conséquent je suis en train de me suicider. C’est ça qu’on devrait nous dire : toute compétition est un suicide. »',
    author: 'Albert Jacquard, 1994'
}, {
    citation: '« Accomplissez chaque journée puis n’y pensez plus: vous avez fait ce que vous pouviez. Quelques erreurs probablement: oubliez les dès que possible. Demain est un autre jour. Commencez le avec sérénité ».',
    author: 'Ralph Waldo Emerson'
}, {
    citation: '« C’est parce que le centre est immobile que a roue tourne ».',
    author: 'Tao Te King'
}, {
    citation: '« Une vision sans l’action n’est qu’un rêve. L’action sans la vision ne mène nulle part. Une vision accompagnée de l’action peut changer le monde».',
    author: 'Loren Eiseley'
}, {
    citation: '« Notre fatigue provient moins souvent de notre travail, que de soucis, rancœurs et ressentiments ».',
    author: 'Dale Carnegie'
}, {
    citation: '« Je suis de plus en plus convaincu que notre bonheur ou notre malheur dépend bien plus de notre façon de considérer les événements de la vie que de la nature même de ces événements ».',
    author: 'Wilhelm Von Humbold'
}, {
    citation: '« Le courage est considéré à juste titre comme la première des qualités humaines parce que c’est celle qui garantit toutes les autres».',
    author: 'Winston Churchill'
}, {
    citation: '« L\'Homme fait un grand pas en avant… en prenant un peu de recul… ».',
    author: 'A. Bennani'
}, {
    citation: '« Quand les choses vont vraiment mal, comme elles savent si bien le faire quelque fois, Quand la route sur laquelle tu chemines péniblement semble s’achever au sommet d’une colline, Quand les fonds sont bas et que les dettes culminent. Quand tu voudrais sourire , et du dois pousser des soupirs, Quand le souci te pousse dans la déprime, Repose-toi un peu… mais n’abandonne pas. La vie est si étrange avec ses revers et ses détours comme chacun de nous a pu l’apprendre un jour, et beaucoup qui ont été abattus par l’échec auraient pu réussir s’ils avaient persévéré… N’abandonne pas même si tout semble aller lentement, car un autre souffle peut apporter la réussite. Le succès n’est que l’envers de l’échec et tu ne peux jamais savoir à quelle distance se trouve le but, qui peut être proche alors qu’il te semble si lointain. Aussi, continue la lutte au plus fort du combat car c’est quand tout semble perdu que tu ne dois pas abandonner. ».',
    author: 'Un ZamiSage inconnu anglais'
}, {
    citation: '« Quoi que tu fasses, l’amour est partout où tu regardes, dans les moindres recoins de l’espace, dans les moindres rêves où tu t’attardes ».',
    author: 'Francis Cabrel'
}, {
    citation: '« Le souci donne une grande ombre à une petite chose ».',
    author: 'Proverbe suédois'
}, {
    citation: '« La voix de l’âme est la respiration ».',
    author: 'Proverbe Tibétain'
}, {
    citation: '« Ne fit-on que des épingles, il faut être enthousiaste de son métier pour y exceller ».',
    author: 'Diderot'
}, {
    citation: '« Il y a des fleurs partout pour qui veut les voir ».',
    author: 'Matisse'
}, {
    citation: '« C’est grâce aux risques que l’on prend que la vie devient vivable».',
    author: 'Charlotte Rampling'
}, {
    citation: '« Ce qui ne peut être évité, il faut l’embrasser».',
    author: 'William Shakespeare'
}, {
    citation: '« L’esprit c’est comme un parachute: s’il reste fermé, on s’écrase».',
    author: 'Frank Zappa'
}, {
    citation: '« Les condoléances ne ressuscitent pas le défunt mais elles entretiennent la confiance entre ceux qui restent».',
    author: 'Proverbe africain'
}, {
    citation: '« N’espère rien de l’homme s’il travaille pour sa propre vie et non pour son éternité».',
    author: 'Antoine de SaintExupéry'
}, {
    citation: '« Personne ne se lasse d’être aidé. L’aide est un acte conforme à la nature. Ne te lasse jamais d’en recevoir ni d’en apporter».',
    author: 'Marc-Aurèle'
}, {
    citation: '« Le corps se guérit par l’amusement: Faites ce qui vous fait plaisir! Le mental se guérit avec le rire: Dédramatisez votre vie! L’âme se guérit avec la joie: Voyez la beauté en toute chose».',
    author: 'Chantal Attia'
}, {
    citation: '« S\'inquiéter n\'effacera pas les problèmes de demain, cela ne fait qu\'enlever la paix d\'aujourd\'hui».',
    author: 'Le ZamiSage'
}, {
    citation: '« Pour réaliser une chose vraiment extraordinaire, commencez par le rêver. Ensuite, réveillez-vous calmement et allez d’un trait jusqu’au bout de votre rêve sans jamais vous laisser décourager».',
    author: 'Walt Disney'
}, {
    citation: '« L’homme est pris pour un fou jusqu’au moment où son idée est acceptée».',
    author: 'Mark Twain'
}, {
    citation: '« Détournez-vous de ceux qui vous découragent de vos ambitions. C\'est l\'habitude des mesquins. Ceux qui sont vraiment grands vous font comprendre que vous aussi pouvez le devenir».',
    author: 'Mark Twain'
}, {
    citation: '« Le lit est l\'endroit le plus dangereux du monde : 99 % des gens y meurent».',
    author: 'Mark Twain'
}, {
    citation: '« Ils ne savaient pas que c\'était impossible, alors ils l\'ont fait».',
    author: 'Mark Twain'
}, {
    citation: '« Un mensonge peut faire le tour de la terre le temps que la vérité mette ses chaussures».',
    author: 'Mark Twain'
}, {
    citation: '« Ceux qui sont pour la liberté sans agitation sont des gens qui veulent la pluie sans orage».',
    author: 'Mark Twain'
}, {
    citation: '« La gentillesse est le langage qu\'un sourd peut entendre et qu\'un aveugle peut voir».',
    author: 'Mark Twain'
}, {
    citation: '« Lorsque vous dites la vérité, vous n\'avez à vous souvenir de rien».',
    author: 'Mark Twain'
}, {
    citation: '« Dans 20 ans, vous serez plus déçu par les choses que vous n’avez pas faites que par celles que vous avez faites. Alors sortez des sentiers battus. Mettez les voiles. Explorez. Rêvez. Découvrez».',
    author: 'Mark Twain'
}, {
    citation: '« Il faut parfois longtemps pour qu’une vérité démontrée devienne une vérité acceptée ».',
    author: 'Gustave Le Bon'
}, {
    citation: '« Vous ne pouvez pas arrêter les vagues, mais vous pouvez apprendre à surfer ».',
    author: 'Joseph Goldstein'
}
];

export default Citations;