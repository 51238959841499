import React, { Component } from 'react';
import ContentTitle from '../Components/ContentTitle';
import Scras from '../Resources/Scras';
import CardShower from '../Components/CardShower';
import BigCardSelector from '../Components/BigCardSelector';
import CardChooser from './CardChooser';
import InputChooser from './InputChooser';

const labels = [
    'Premier Scra',
    'Second Scra',
    'Troisième Scra',
    'Dernier Scra'
];

export default class Intro extends Component{
    constructor(props){
        super(props);
        this.state = {
            introTopic: '',
            scras: [[], [], [], []],
            addingTo: -1,
            selecting: false,
            cardToShow: null,
            choosingTopic: false,
            isGameHidden: false
        };

        this.addCard = this.addCard.bind(this);
        this.toggleSelectionPanel = this.toggleSelectionPanel.bind(this);
        this.showCard = this.showCard.bind(this);
        this.deleteCard = this.deleteCard.bind(this);
        this.chooseCard = this.chooseCard.bind(this);
        this.hideCard = this.hideCard.bind(this);
        this.toggleTopicSelection = this.toggleTopicSelection.bind(this);
        this.chosenTopic = this.chosenTopic.bind(this);
    }

    addCard(idx, callback){
        this.setState({
            addingTo: idx
        }, callback);
    }

    toggleFullScreen(){
        if (document.fullscreenElement || 
            document.webkitFullscreenElement || 
            document.mozFullScreenElement) {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) { /* Firefox */
            document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
            document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { /* IE/Edge */
            document.msExitFullscreen();
            }
        } else {
            document.getElementsByClassName("app")[0].requestFullscreen();
        }
    }

    componentDidMount(){
        document.getElementsByClassName("app")[0].requestFullscreen();
    }

    componentWillUnmount(){
        if (document.fullscreenElement || 
            document.webkitFullscreenElement || 
            document.mozFullScreenElement) {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) { /* Firefox */
            document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
            document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { /* IE/Edge */
            document.msExitFullscreen();
            }
        }
    }
    
    chooseCard(cardsRef, listCards, idx, callback){
        let selectedCards = Object.assign([], this.state.scras);
        if(selectedCards.length > this.state.addingTo && this.state.addingTo > -1){
            let cards = selectedCards[this.state.addingTo];
            cards.push(idx);
            this.setState({scras: selectedCards}, callback);
        } else {
            callback();
        }
    }

    toggleTopicSelection(){
        const newState = !this.state.choosingTopic;
        this.setState({
            choosingTopic: newState,
            isGameHidden: newState
        });
    }

    toggleSelectionPanel(){
        const newState = !this.state.selecting;
        this.setState({
            selecting: newState,
            isGameHidden: newState
        });
    }

    showCard(card, index){
        this.setState({
            cardToShow: card,
            isGameHidden: true,
            addingTo: index
        });
    }

    deleteCard(){
        if(this.state.addingTo > -1){
            const scras = this.state.scras;
            scras[this.state.addingTo] = [];
            this.setState({
                scras
            }, this.hideCard);
        }
    }

    hideCard(){
        this.setState({
            cardToShow: null,
            isGameHidden: false
        });
    }

    chosenTopic(topic){
        this.setState({
            introTopic: topic ? topic : '',
            choosingTopic: false,
            isGameHidden: false
        });
    }

    render() {
        return (
            <div className="content reduced">
                <ContentTitle text="Quatre Scras discutent à propos de:"/>
                <button id="fullScreenToggle" aria-hidden="true" onClick={this.toggleFullScreen} tabIndex="-1"><i className="material-icons" tabIndex="-1">zoom_out_map</i></button>
                <div>
                    {
                        this.state.choosingTopic ?
                            <InputChooser   returnText={this.chosenTopic}
                                            title={'De quoi parlent les Scras ?'}
                                            actualText={this.state.introTopic}
                                            closingMethod={this.toggleTopicSelection}/> : ''
                    }
                    {
                        this.state.cardToShow ?
                            <CardShower card={this.state.cardToShow}
                                        deleteCard={this.deleteCard}
                                        closingMethod={this.hideCard}/> : ''
                    }
                    {
                        this.state.selecting ?
                            <CardChooser    cardsList={Scras} 
                                            selectedCardsIndex={'selectedScras'}
                                            chooseCard={this.chooseCard} 
                                            closingMethod={this.toggleSelectionPanel}
                                            title={'Ajouter un Scra'}/> : ''
                    }
                    <div className="absolute tableContainer">
                        <button onClick={this.toggleTopicSelection} className="selectableElement" tabIndex={this.state.isGameHidden ? -1 : 1}>
                            <div className="absolute table">
                                <div>{this.state.introTopic === '' ? 'De quoi parlent les scras ?' : this.state.introTopic}</div>
                            </div>
                        </button>
                    {
                        [0, 1, 2, 3].map(idx =>
                            <div className="scraIntro" id={'scraIntro' + idx} key={'card' + idx}>
                                <BigCardSelector showCard={(card) => {this.showCard(card, idx)}}
                                selectedCards={this.state.scras[idx]}
                                cardsList={Scras}
                                addCard={() => {this.addCard(idx, () => this.toggleSelectionPanel())}} 
                                limit={1} 
                                title={'Scras'}
                                notFocusable={this.state.isGameHidden}
                                label={labels[idx]}/>        
                            </div>
                        )
                    }
                    </div>
                </div>
                <nav>
                    <button tabIndex={this.state.isGameHidden ? -1 : 1} className="absolute buttonBack" onClick={this.props.backAction}>&lt; Retour</button>
                </nav>
            </div>
        );
    }
}