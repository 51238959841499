import React, {Component} from 'react';
import SubTitle from '../Components/SubTitle';
import SelectionPanel from '../Components/SelectionPanel';

class SaveGamePrompt extends Component{
    constructor(props){
        super(props);
        this.state = {
            name: '',
            place: '',
            participants: [''],
            shortDescription: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.saveGameClicked = this.saveGameClicked.bind(this);
        this.handleParticipantChange = this.handleParticipantChange.bind(this);
    }

    componentDidMount(){
        this.setState({
            name: '',
            place: '',
            shortDescription: ''
        }, () => {
            this.nameInput.focus();
        });
    }

    handleChange(event){
        this.setState({
            [event.target.id]: event.target.value 
        });
    }

    handleParticipantChange(event, index){
        let participantsList = Object.assign([], this.state.participants);
        participantsList[index] = event.target.value;
        if(event.target.value === ''){
            participantsList.splice(index, 1);
        } else if(event.target.value !== '' && participantsList.length <= index + 1){
            participantsList.push('');
        }
        this.setState({
            participants: participantsList
        });
    }

    saveGameClicked(){
        this.props.saveGame(this.state.name,
                        this.state.place, 
                        this.state.shortDescription,
                        this.state.participants);
    }

    render(){
        return (
            <SelectionPanel onClick={this.props.closingMethod}>
                <div role="document" id="dialog" aria-label={'Enregistrer la partie'} className="inputChooser">
                    <SubTitle text="Enregistrer la partie"/>
                    <div className="descriptionContainer saveContainer">
                        <input id="name" 
                                type="text" 
                                onChange={this.handleChange} 
                                value={this.state.name}
                                placeholder='Nom de la partie'
                                ref={(input) => {this.nameInput = input;}} />
                        <input id="place" 
                                type="text" 
                                onChange={this.handleChange} 
                                value={this.state.place}
                                placeholder='Lieu' />
                        {
                            this.state.participants.map((participant, index) =>
                                <input type="text"
                                        key={`part${index}`}
                                        onChange={(event) => {this.handleParticipantChange(event, index)}}
                                        value={participant}
                                        placeholder="Ajouter un participant..." />
                            )
                        }
                        <textarea
                            id="shortDescription"
                            value={this.state.shortDescription} 
                            onChange={this.handleChange}
                            placeholder='Courte description'></textarea>
                    </div>
                    <div className="inputChooserButton">
                        <button 
                            onClick={this.saveGameClicked}
                            disabled={this.state.name === ''}>Enregistrer</button>
                    </div>
                </div>
            </SelectionPanel>
        );
    }
}

export default SaveGamePrompt;