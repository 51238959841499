const Traverse = [
    {
        name: 'Avidité',
        image: 'traverse/avidite.jpg'
    },
    {
        name: 'Tristesse',
        image: 'traverse/tristesse.jpg'
    },
    {
        name: 'Résignation',
        image: 'traverse/resignation.jpg'
    },
    {
        name: 'Agressivité',
        image: 'traverse/agressivite.jpg'
    },
    {
        name: 'Doute',
        image: 'traverse/doute.jpg'
    },
    {
        name: 'Facilité',
        image: 'traverse/facilite.jpg'
    }
];

export default Traverse;