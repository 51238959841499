const Enums = {
    gameStatus: {
        definition: 0,
        startDefined: 1,
        endDefined: 2,
        secondaryDefined: 3,
        firstObstacleDefined: 4,
        secondObstacleDefined: 5,
        thirdObstacleDefined: 6,
        attitudeDefined: 7,
        started: 8,
        firstStep: 9,
        secondStep: 10,
        thirdStep: 11,
        finished: 12
    }
};

export default Enums;