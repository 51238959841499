import React from 'react';
import SubTitle from '../Components/SubTitle';
import SelectionPanel from '../Components/SelectionPanel';

const CardShower = (props) => {
    const deleteCard = 'Supprimer ' + props.card.name;

    return(
        <SelectionPanel onClick={props.closingMethod}>
            <div role="document" aria-label={props.card.name} className="cardShower">
                <SubTitle text={props.card.name}/>
                <div className="descriptionContainer">
                    <div>
                        <div className="hugeCardContainer">
                            <div className="hugeCard">
                                <h3 style={{marginBottom: 0}}>{props.card.name}</h3>
                                <img src={'./img/' + props.card.image} alt="" />
                            </div>
                        </div>
                        <div tabIndex="1" className="cardDescription" dangerouslySetInnerHTML={{__html: props.card.description}}></div>
                        <div tabIndex="1" className="freins">{props.card.freins}</div>
                    </div>
                </div>
                <div className="buttonChoiceContainer">
                    <button aria-label={deleteCard}
                            onClick={props.deleteCard}>
                        {deleteCard}
                    </button>
                    <button aria-label='Fermer'
                            onClick={props.closingMethod}>
                        Fermer
                    </button>
                </div>
            </div>
        </SelectionPanel>
    );
}

export default CardShower;