import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Route } from 'react-router';
import Login from './Containers/Login';
import Welcome from './Containers/Welcome';
import Intro from './Containers/Intro';
import New from './Containers/New';
import Old from './Containers/Old';
import Game from './Containers/Game';
import Admin from './Containers/Admin';
import Account from './Containers/Account';
import Cookies from 'js-cookie';
import * as Api from './Communication/Api';
import './App.css';
import User from './Model/User';

class App extends Component {
  constructor(props){
    super(props);
    this.state = {
      config: null,
      user: null,
      errorLogin: null,
      gameStarted: false,
      offline: false,
      testingConnectivity: true
    };

    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.loadGame = this.loadGame.bind(this);
    this.startGame = this.startGame.bind(this);
    this.updateUserFullName = this.updateUserFullName.bind(this);
    this.keyPressed = this.keyPressed.bind(this);

    this.keyListeners = [];

    this.getCookies();

    document.onkeyup = this.keyPressed;
  }

  keyPressed = (evt) => {
    if(evt.key === 'Escape'){
      const overlays = document.getElementsByClassName("overlay");
      if(overlays.length > 0){
        overlays[0].click();
      }
    }
    if(evt.keyCode === 8){
      evt.preventDefault();
    }
  }

  componentDidMount(){
    //document.documentElement.requestFullscreen();
    Api.testConnectivity().then(result => {
      this.setState({
        offline: result,
        testingConnectivity: false
      }, this.getCookies);
    });
  }

  checkAndCorrectPath = () => {
    const user = this.state.user,
          config = this.state.config;
    if(!user){
      this.props.history.push('/');
      return;
    }
    switch(this.props.location.pathname){
      case '/' : 
          this.props.history.push('/welcome');
          return;
      case '/game':
        if(!config){
          this.props.history.push('/new');
          return;
        }
        break;
      default: break;
    }
    this.render();
  };

  getCookies = () => {
    const config = Cookies.getJSON('config');
    const user = Cookies.getJSON('scrzm');
    if(user){
      Api.checkUserKey(user.username, user.key).then(() => {
        this.setState({
          config: config ? Object.assign({}, config) : null,
          user: user ? Object.assign({}, user) : null
        }, this.checkAndCorrectPath);
      }).catch(() => {
        this.logout(user.username);
      });
    }
  };

  login = (username, password) => {
    this.setState({
      errorLogin: null
    });

    Api.loginUser(username, password).then((userDto) => {
      const user = new User(
        userDto.id,
        userDto.user_login,
        userDto.user_name,
        userDto.administrator,
        userDto.log_key
      );
      Cookies.set('scrzm', user);
      this.setState({
        user
      }, this.checkAndCorrectPath);
    }).catch((message) => {
      this.setState({
        errorLogin: message
      });
    });
  };

  updateUserFullName = (fullname) => {
    const user = Object.assign({}, this.state.user);
    user.fullname = fullname;
    Cookies.set('scrzm', user);
    this.setState({
      user
    });
  }

  logout = (username) => {
    Api.logoutUser(username).then(() => {
      Cookies.remove('scrzm');
      this.props.history.push('/');
      this.setState({
        user: null
      });
    });
  };

  loadGame = (savedGame) => {
    const gameData = Api.uncompressData(savedGame.data);
    gameData.game.isGameHidden = false;
    Cookies.set('config', gameData.config);
    this.setState({
      config: Object.assign({}, gameData.config),
      loadedGame: Object.assign({}, gameData.game),
      gameStarted: true
    }, () => {
      this.props.history.push('/game');
    });
  };

  startGame = (config) => {
    Cookies.set('config', config);
    this.setState({
      config: Object.assign({}, config),
      loadedGame: null,
      gameStarted: true
    }, () => {
      this.props.history.push('/game');
    });
  };

  bannerClicked = () => {
    this.setState({gameStarted: false}, () => {this.props.history.push('/welcome')});
  };

  render() {
    return (
        <div className="app">
          <div className={this.props.location.pathname === '/' || 
                    this.props.location.pathname === '/welcome' ? 'banner' : 'bannerReduced'}>
            <img  className={this.props.location.pathname === '/' || 
                          this.props.location.pathname === '/welcome' ? 'bannerImg' : 'bannerImgReduced'}
                ref={image => this.scrazamiBanner = image}
                src="./img/logo_scrazami.png" 
                alt="Logo ScraZami"
                onClick={this.props.location.pathname === '/' ? null : this.bannerClicked}/>
          </div>
          <React.Fragment>
            <Route exact path="/" render={(props) => <Login handleUserLogin={this.login} offline={this.state.offline} testingConnectivity={this.state.testingConnectivity} errorLogin={this.state.errorLogin} history={this.props.history}/>} />
            <Route exact path="/welcome" render={(props) => <Welcome user={this.state.user} history={this.props.history} disconnect={this.logout} />}/>
            <Route exact path="/intro" render={(props) => <Intro user={this.state.user} history={this.props.history} backAction={this.bannerClicked} />}/>
            <Route exact path="/new" render={(props) => <New user={this.state.user} history={this.props.history} config={this.state.config} startGame={this.startGame} backAction={this.bannerClicked} />}/>
            <Route exact path="/game" render={(props) => <Game user={this.state.user} history={this.props.history} config={this.state.config} loadedGame={this.state.loadedGame} backAction={this.bannerClicked} />}/>
            <Route exact path="/old" render={(props) => <Old user={this.state.user} history={this.props.history} loadGame={this.loadGame} backAction={this.bannerClicked} />}/>
            <Route exact path="/admin" render={(props) => <Admin user={this.state.user} history={this.props.history} backAction={this.bannerClicked} />} />
            <Route exact path="/account" render={(props) => <Account user={this.state.user} history={this.props.history} updateUserFullName={this.updateUserFullName} backAction={this.bannerClicked} />} />
          </React.Fragment>
        </div>
    );
  }
}

export default withRouter(App);
