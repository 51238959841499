import React from 'react';
import Citations from '../Resources/Citations';

const CitationSelector = (props) => {
    return (
        <React.Fragment>
            {
                props.selectedCitation !== null ?
                <button tabIndex={props.notFocusable ? -1 : 1} className="selectableElement" aria-label={props.label + ' ' + Citations[props.selectedCitation].citation} onClick={(evt) => props.showCitation(props.selectedCitation, evt.target)}>
                    <div className={'citation absolute'}>
                        <img src={'./img/' + props.zamiSage.image} alt="citation" />
                    </div>
                </button>
                :
                <button tabIndex={props.notFocusable ? -1 : 1} className="selectableElement" aria-label={props.label} onClick={props.addCard}>
                    <div className={'addCitation absolute'}>
                        <h4>{props.title}</h4>
                    </div>
                </button>
            }
        </React.Fragment>
    );
}

export default CitationSelector;