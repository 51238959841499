import React, {Component} from 'react';
import SubTitle from '../Components/SubTitle';
import SelectionPanel from '../Components/SelectionPanel';

export default class InputChooser extends Component{
    constructor(props){
        super(props);
        this.state = {
            text: props.actualText
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount(){
        this.setState({
            text: this.props.actualText
        }, this.nameInput.focus());
    }

    componentDidUpdate(prevProps){
        if(this.props.title !== prevProps.title){
            this.setState({
                text: this.props.actualText ? this.props.actualText : ''
            }, this.nameInput.focus());
        }
    }

    handleChange(event){
        this.setState({
            text: event.target.value 
        });
    }


    render(){
        return(
            <SelectionPanel onClick={this.props.closingMethod}>
                <div role="document" id="dialog" aria-label={this.props.title} className="inputChooser">
                    <SubTitle text={this.props.title}/>
                    <div className="descriptionContainer inputContainer">
                        <textarea 
                            id="textInput"
                            value={this.state.text} 
                            onChange={this.handleChange}
                            ref={(input) => {this.nameInput = input;}}></textarea>
                        <label htmlFor="textInput">{this.props.title}</label>
                    </div>
                    <div className="inputChooserButton">
                        <button onClick={() => {this.props.returnText(this.state.text)}} disabled={(!this.state.text || this.state.text === '') && !this.props.emptyOk}>Ajouter</button>
                    </div>
                </div>
            </SelectionPanel>
        );
    }
}