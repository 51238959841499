import Cookies from 'js-cookie';
import _ from 'lodash';
import LZString from 'lz-string';
import sha from 'sha.js';

const DEBUG = false;

const CK_SAVE = 'CK_SAVE';
const url = (DEBUG ? 'http://localhost/scrazami' : '.') + '/backend/index.php';
var offline = false;

const postData = (action, data) => {
    var formData = new FormData();
    formData.append('action', action);
    formData.append('data', encodeURI(JSON.stringify(data)));
    return new Promise((resolve, reject) => {
        return fetch(url, {
            method: 'POST',
            body: formData
        }).then(ret => {
            return ret.text().then(response => {
                const parsed = JSON.parse(response);
                if(parsed.success){
                    resolve(parsed.message);
                } else {
                    reject(parsed.message);
                }
            });
        }).catch(message => {
            reject(message.message);
        });
    });
};

export const testConnectivity = () => {
    return new Promise((resolve, reject) => {
        if(offline){
            resolve(true);
        }
        return fetch(url + '?action=connectivity')
        .then(function(ret){
            return ret.text();
        })
        .then(function(response){
            offline = false;
            resolve(false);
        })
        .catch((msg) => {
            offline = true;
            resolve(true);
        });
    });
}

export const loginUser = (username, password) => {
    if(offline){
        return new Promise((resolve, reject) => {
            resolve({
                user_login: username,
                user_name: username,
                administrator: 0
            });
        });
    }
    const shaPass = sha('sha512').update(password).digest('hex');
    return postData('login', {
        username: username,
        pass: shaPass
    });
}

export const checkUserKey = (username, key) => {
    return postData('checkKey', {
        username: username,
        key: key
    });
}

export const logoutUser = (username) => {
    if(offline){
        return new Promise((resolve, reject) => {
            resolve();
        });
    }
    return postData('logout', {
        username: username
    });
}

export const uncompressData = (data) => {
    const uncompressedData = LZString.decompressFromEncodedURIComponent(data);
    return JSON.parse(uncompressedData);
};

export const saveGame = (gameData, name, place, description, participants, userId, username, key) => {
    const data = LZString.compressToEncodedURIComponent(JSON.stringify(gameData));
    const date = new Date();
    const timestamp = date.getTime();
    const dataToSave = {
        data: data,
        name: name,
        place: place,
        participants: participants.filter(participant => participant !== ''),
        date: timestamp,
        description: description,
        user_id: userId
    };
    if(offline){
        const compressedCookie = Cookies.get(CK_SAVE);
        let savedGames = compressedCookie && compressedCookie.length > 0 ? uncompressData(compressedCookie) : null;
        if(!savedGames){
            savedGames = [];
        }
        dataToSave.participants = _.map(dataToSave.participants, (part) => {
            return {
                part_name: part
            }
        });
        savedGames.push(dataToSave);
        const compressedString = LZString.compressToEncodedURIComponent(JSON.stringify(savedGames));
        Cookies.set(CK_SAVE, compressedString);
    } else {
        return postData('saveGame', {
            username,
            key,
            game: dataToSave
        });
    }
}

export const deleteGame = (username, key, gameId) => {
    if(offline){
        let array = [];
        const compressedCookie = Cookies.get(CK_SAVE);
        if(compressedCookie && compressedCookie.length > 0){
            const games = uncompressData(compressedCookie);
            _.each(games, (game) => {
                game.data = uncompressData(game.data);
            });
            array = games;
        }
        if(gameId > -1){
            array.splice(gameId, 1);
            saveGamesList(array);
            return loadGamesList();
        }
    } else {
        return postData('deleteGame', {
            username,
            key,
            gameId
        });
    }
}

export const loadGame = (name, timestamp) => {
    if(offline){
        const compressedCookie = Cookies.get(CK_SAVE);
        const savedGames = uncompressData(compressedCookie);
        return _.find(savedGames, (game) => {
            return (game.name + game.date) === (name + timestamp);
        });
    }
}

export const loadGamesList = (username, key) => {
    if(offline){
        let array = [];
        const compressedCookie = Cookies.get(CK_SAVE);
        if(compressedCookie && compressedCookie.length > 0){
            const games = uncompressData(compressedCookie);
            array = games;
        }
        return new Promise((resolve, reject) => {
            resolve(array);
        });
    } else {
        return postData('gameList', {
            username,
            key
        });
    }
}

export const saveGamesList = (gamesList) => {
    if(offline){
        const compressedString = LZString.compressToEncodedURIComponent(JSON.stringify(gamesList));
        Cookies.set(CK_SAVE, compressedString);
    }
}

export const getUsersList = (username, key) => {
    return postData('userList', {
        username,
        key
    });
}

export const deleteUser = (username, key, userId) => {
    return postData('deleteUser', {
        username,
        key,
        userId
    });
}

export const saveUser = (username, key, user) => {
    let shaPass = '';
    if(user.password){
        shaPass = sha('sha512').update(user.password).digest('hex');
    }    
    return postData('saveUser', {
        username,
        key,
        user: {
            id: user.id,
            user_login: user.username,
            user_name: user.fullname,
            pass: shaPass,
            administrator: user.admin
        }
    });
}