import React, { Component } from 'react';
import ContentTitle from '../Components/ContentTitle';
import Footer from '../Components/Footer';

export default class Login extends Component{
    constructor(props){
        super(props);
        this.state = {
            login: '',
            password: ''
        };

        this.handleLoginChange = this.handleLoginChange.bind(this);
        this.handlePassChange = this.handlePassChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount(){
        this.loginInput.focus();
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.props.handleUserLogin(this.state.login, this.state.password);
    };

    handleLoginChange = (evt) => {
        this.setState({
            login: evt.target.value
        });
    };

    handlePassChange = (evt) => {
        this.setState({
            password: evt.target.value
        });
    };

    componentWillReceiveProps(){
        this.render();
    };

    render(){
        return (
            <div className="content">
                <ContentTitle text="Se Connecter" />
                <div className="contentZone">
                    <div className="centeredText">
                        <span className={this.props.testingConnectivity || this.props.offline ? 'warning': ''}>
                            {
                                this.props.testingConnectivity ? 'Tentative de connexion au serveur...':
                                (this.props.offline ? 'Serveur ScraZami non trouvé, l\'application fonctionne en mode hors-ligne' : '')
                            }
                        </span></div>
                    <form className="login" onSubmit={this.handleSubmit}>
                        <input type="text" ref={(input) => {this.loginInput = input;}} onChange={this.handleLoginChange} value={this.state.login} placeholder="Nom d'utilisateur" />
                        <input type="password" onChange={this.handlePassChange} value={this.state.password} placeholder="Mot de passe" />
                        <input type="submit" value="Se connecter" />
                        {
                            this.props.errorLogin ?
                            <div className="centeredText error">{this.props.errorLogin}</div> : ''
                        }
                    </form>
                </div>
                <Footer />
            </div>
        );
    }
};