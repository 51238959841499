import React from 'react';
import SubTitle from '../Components/SubTitle';
import SelectionPanel from '../Components/SelectionPanel';

const InputShower = (props) => {
    return(
        <SelectionPanel onClick={props.closingMethod}>
            <div role="document" aria-label={props.title} className="inputShower">
                <SubTitle text={props.title}/>
                <div tabIndex="1" className="descriptionContainer">
                    <div>{props.text}</div>
                </div>
            </div>
        </SelectionPanel>
    );
}

export default InputShower;