import React, { Component } from 'react';
import ContentTitle from '../Components/ContentTitle';

export default class New extends Component{
    constructor(props){
        super(props);
        const config = props.config;
        this.state = {
            useDetours: config ? config.useDetours : false,
            useMagic: config ? config.useMagic : false,
            useAttitudes: config ? config.useAttitudes : false
        }
        this.handleDetourChange = this.handleDetourChange.bind(this);
        this.handleMagicChange = this.handleMagicChange.bind(this);
        this.handleAttitudesChange = this.handleAttitudesChange.bind(this);
        this.startGame = this.startGame.bind(this);
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps.config) !== JSON.stringify(this.props.config)){
            const config = this.props.config;
            this.setState({
                useDetours: config ? config.useDetours : false,
                useMagic: config ? config.useMagic : false,
                useAttitudes: config ? config.useAttitudes : false
            })
        }
        this.render();
    }

    getEventValue = (evt) => {
        if(evt.target.value === 'oui')
            return true;
        return false;
    }

    handleDetourChange = (evt) => {
        this.setState({
            useDetours: this.getEventValue(evt)
        });
    }

    handleMagicChange = (evt) => {
        this.setState({
            useMagic: this.getEventValue(evt)
        });
    }

    handleAttitudesChange = (evt) => {
        this.setState({
            useAttitudes: this.getEventValue(evt)
        });
    }

    startGame = () => {
        this.props.startGame({
            useDetours: this.state.useDetours,
            useMagic: this.state.useMagic,
            useAttitudes: this.state.useAttitudes
        });
    };

    selectedStyle(){
        return {
            backgroundColor: '#fecd23',
            color: 'white'
        };
    }

    unselectedStyle(){
        return {
            backgroundColor: 'white',
            color: 'black',
            opacity: '0.5'
        };
    }

    getStyle(property){
        return {
            backgroundColor: property ? '#fecd23' : 'white',
            color: property ? 'white' : 'black'
        };
    }

    render() {
        return (
            <div className="content reduced">
                <ContentTitle text="Nouvelle Partie" />
                <div className="setup">
                    <form onSubmit={this.startGame}>
                        <fieldset>
                            <legend>Chemins de travers: </legend>
                                <input type="radio" onChange={this.handleDetourChange} checked={this.state.useDetours} id="chemins1" name="chemins" value="oui" />
                                <label htmlFor="chemins1" style={this.getStyle(this.state.useDetours)}>Oui</label>
                                <input type="radio" onChange={this.handleDetourChange} checked={!this.state.useDetours} id="chemins2" name="chemins" value="non" />
                                <label htmlFor="chemins2"  style={this.getStyle(!this.state.useDetours)}>Non</label>
                        </fieldset>
                        <fieldset>
                            <legend>Magie: </legend>
                                <input type="radio" onChange={this.handleMagicChange} checked={this.state.useMagic} id="magie1" name="magie" value="oui" />
                                <label htmlFor="magie1" style={this.getStyle(this.state.useMagic)}>Oui</label>
                                <input type="radio" onChange={this.handleMagicChange} checked={!this.state.useMagic} id="magie2" name="magie" value="non" />
                                <label htmlFor="magie2" style={this.getStyle(!this.state.useMagic)}>Non</label>
                        </fieldset>
                        <fieldset>
                            <legend>Attitudes: </legend>
                                <input type="radio" onChange={this.handleAttitudesChange} checked={this.state.useAttitudes} id="attitudes1" name="attitudes" value="oui" />
                                <label htmlFor="attitudes1" style={this.getStyle(this.state.useAttitudes)}>Oui</label>
                                <input type="radio" onChange={this.handleAttitudesChange} checked={!this.state.useAttitudes} id="attitudes2" name="attitudes" value="non" />
                                <label htmlFor="attitudes2" style={this.getStyle(!this.state.useAttitudes)}>Non</label>
                        </fieldset>
                    </form>
                    <input type="submit" value="Démarrer la partie" style={{fontSize:'large'}} onClick={this.startGame} />
                </div>
            </div>
        );
    }
}