import React from 'react';

const BigCardSelector = (props) => {
    const canAddCard = props.selectedCards.length < props.limit;
    return (
        <div className="absolute bigCardSelector">
            {
                canAddCard ?
                <button className="addBigCard" onClick={props.addCard} tabIndex={props.notFocusable ? -1 : 1} aria-label={props.label}>
                    <div className="">
                        <div className="plusButton" aria-hidden="true">+</div>
                        <h4>{props.title}</h4>
                    </div>
                </button> : ''
            }
            {
                props.selectedCards.map((cardIdx, index) => {
                    const card = props.cardsList[cardIdx];
                    return(
                    <button className="bigCard"
                            key={card.name + index} 
                            onClick={() => props.showCard ? props.showCard(card) : null} 
                            tabIndex={props.notFocusable ? -1 : 1}
                            aria-label={card.name}>
                    <div
                        style={{
                        zIndex: 10 - index,
                        left: ((65 * index) + (canAddCard ? 65 : 0)) + 'px',
                        bottom: ((27 * index) + (canAddCard ? 27 : 0)) + 'px'
                    }}>
                        <h4>{card.name}</h4>
                        <img src={'./img/' + card.image} alt={card.name} />
                    </div>
                    </button>);
                })
            }
        </div>
    );
}

export default BigCardSelector;